import { FC } from 'react';
import { DashboardContainer } from 'src/containers/DashboardContainer';
import { Button } from 'src/components/kit/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks/useRedux';
import { fetchUser } from 'src/store/user/actions';
import { howCreateChatBot, navList } from 'src/config/faqData';
import OnBoardingService from 'src/services/OnBoardingService';
import parse from 'html-react-parser';

import { ReactComponent as ShapeLeftIcon } from 'src/assets/icons/kit/shape-left.svg';
import { ReactComponent as CameraIcon } from 'src/assets/icons/kit/camera.svg';
import phone from 'src/assets/images/phone.png';
import msg from 'src/assets/images/msg.png';

export const FAQ: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleResetOnBoarding = async () => {
    await OnBoardingService.resetSteps();
    await dispatch(fetchUser());
    navigate('/');
  };

  return (
    <DashboardContainer
      search
      title="FAQ"
      description="Здесь вы можете найти ответы на интересующие вас вопросы"
      classNames="flex flex-row"
    >
      <div
        className={
          'w-full t-lg:w-[38rem] t-lg:min-w-[38rem] d-xs:w-[50rem] d-xs:min-w-[50rem] flex flex-col gap-6'
        }
      >
        <div className="text-3xl font-gilroy-500">Как создать чат-бот</div>

        <div className="flex gap-8 w-full">
          <div className="font-gilroy-400 w-2/3 blue-links">
            <div className="mb-8">{parse(howCreateChatBot.title)}</div>
            {howCreateChatBot.leftList.map((el, i) => (
              <div className={el.classNames} key={`how-create-chat-bot-left-${i}`}>
                {parse(el.title)}
              </div>
            ))}
          </div>

          <div className="w-1/3 flex flex-col gap-8">
            <div className="w-full h-56">
              <img src={phone} className="w-full h-full object-contain" alt="" />
            </div>

            {howCreateChatBot.rightList.map((el, i) => (
              <div className="flex flex-col gap-4 w-full" key={`how-create-chat-bot-right-${i}`}>
                <div className="w-full relative h-16 flex items-center justify-center">
                  <div className="absolute top-0 right-0 w-full h-full">
                    <img src={msg} className="w-full h-full object-contain" alt="" />
                  </div>
                  <div className="text-2xl text-white font-gilroy-700 relative">{el.text}</div>
                </div>

                <div className="w-full flex items-end ">
                  <ShapeLeftIcon className="w-2.5 h-2.5 min-w-[0.625rem] fill-white -mr-0.5" />
                  <div
                    className={
                      'bg-white font-gilroy-400 rounded-t-xl rounded-br-xl p-2.5 ' +
                      'whitespace-pre-wrap blue-links'
                    }
                  >
                    {parse(el.answer)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="ml-auto flex flex-col">
        {navList.map((el, i) => (
          <a
            href={el.href}
            key={`faq-nav-${i}`}
            className="text-turquoise-100 underline font-gilroy-500 ml-auto"
          >
            {el.text}
          </a>
        ))}
        <Button
          variant="green"
          size="small"
          classNames="mt-4 ml-auto w-fit p-0"
          onClick={handleResetOnBoarding}
        >
          Тур по приложению
          <CameraIcon className="w-4 h-4 min-w-[1rem] fill-white" />
        </Button>
      </div>
    </DashboardContainer>
  );
};
