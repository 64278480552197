import { FC } from 'react';
import cn from 'classnames';

interface DiscountProps {
  discount?: string;
  size: 'lg' | 'sm';
  classNames?: string;
}

export const Discount: FC<DiscountProps> = ({ discount, size, classNames }) => {
  if (!discount || +discount <= 0) {
    return <></>;
  }

  return (
    <div
      className={cn(
        { 'text-lg px-3.5 py-2': size === 'lg', 'text-xs px-2 py-0.5': size === 'sm' },
        'rounded-tl-4xl bg-gradient-turquoise-300 font-gilroy-600 text-white',
        classNames,
      )}
    >
      -{discount}%
    </div>
  );
};
