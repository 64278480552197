import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { Message } from 'src/components/Message';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { Loader } from 'src/components/Loader';
import { SupportSendForm } from 'src/components/Forms/SupportSendForm';
import { GetSupportMessagesType, SupportIncidentType, SupportMessageType } from 'src/config/types';
import { useAppSelector } from 'src/hooks/useRedux';
import SupportService from 'src/services/SupportService';

interface SupportMessagesListProps {
  messages: SupportMessageType[];
  setMessages: Dispatch<SetStateAction<SupportMessageType[]>>;
  markAsRead: (incidentId: string, count?: number) => void;
  selectedIncident: SupportIncidentType;
  handleSendMessage: () => void;
  isMessagesLoading: boolean;
}

export const SupportMessagesList: FC<SupportMessagesListProps> = ({
  messages,
  setMessages,
  markAsRead,
  selectedIncident,
  handleSendMessage,
  isMessagesLoading,
}) => {
  const {
    shops: { selectedShop },
  } = useAppSelector(state => state);
  const { width } = useWindowDimensions();
  const ref = useRef<HTMLDivElement>(null);
  const limit = 20;

  const checkScroll = async e => {
    if (
      selectedShop &&
      selectedIncident &&
      (e.target as HTMLDivElement).scrollTop === 0 &&
      +selectedIncident.unreadMessagesCount > 0
    ) {
      await SupportService.supportMarkAsRead(selectedShop?.id || '', selectedIncident.user.id).then(
        () => {
          markAsRead(selectedIncident.incidentId);
        },
      );
    }
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('scroll', checkScroll);
    }
    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener('scroll', checkScroll);
      }
    };
  }, [selectedIncident, selectedShop, checkScroll]);

  const loadMore = async () => {
    if (!selectedIncident || !selectedShop) {
      return;
    }

    const params: GetSupportMessagesType = {
      userId: selectedIncident.user.id,
      shopId: selectedShop.id,
      LastMessageId: messages[messages.length - 1].id,
      direction: 'up',
      limit,
    };

    const res = await SupportService.getSupportMessages(params);
    setMessages(prev => [...prev, ...res.data]);
  };

  return (
    <>
      <div
        className="flex flex-col-reverse gap-2 overflow-auto px-4 pt-2.5 t-lg:px-5 t-lg:pt-8"
        style={{ maxHeight: `calc(100% - ${width >= 1024 ? 72 : 106}px)` }}
        ref={ref}
        id="support-chat"
      >
        {isMessagesLoading && !messages.length ? (
          <Loader />
        ) : (
          messages.map((message, i) => (
            <Message
              key={`support-message-${message.id}`}
              message={message}
              withObserver={i === messages.length - 4}
              loadMore={loadMore}
              selectedIncident={selectedIncident}
            />
          ))
        )}
      </div>
      {selectedShop ? (
        <SupportSendForm
          userId={selectedIncident.user.id}
          shopId={selectedShop?.id || ''}
          onSend={handleSendMessage}
        />
      ) : null}
    </>
  );
};
