import { PaymentInitializeType } from 'src/config/types';
import { PaymentApi } from 'src/services/Api/PaymentApi';
import { paramsToQuery } from 'src/utils';

const initialize = (params: PaymentInitializeType) => {
  return PaymentApi.post(`/Payment/initialize?${paramsToQuery(params)}`);
};

const checkPayment = (orderId: string) => {
  return PaymentApi.post(`/Payment/getState?orderId=${orderId}`);
};

const PaymentService = { initialize, checkPayment };

export default PaymentService;
