import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonState } from 'src/store/common/types';

const initialState: CommonState = {
  globalError: '',
  globalSearch: '',
  isSupportModalOpen: false,
  isOpenTariffEndModal: false,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setGlobalError: (state, action: PayloadAction<string>) => {
      state.globalError = action.payload;
    },
    setGlobalSearch: (state, action: PayloadAction<string>) => {
      state.globalSearch = action.payload;
    },
    setIsSupportModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isSupportModalOpen = action.payload;
    },
    setIsOpenTariffEndModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenTariffEndModal = action.payload;
    },
  },
  extraReducers: {},
});

export const { setGlobalError, setGlobalSearch, setIsSupportModalOpen, setIsOpenTariffEndModal } =
  commonSlice.actions;

const reducer = commonSlice.reducer;
export default reducer;
