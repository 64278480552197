import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Button } from 'src/components/kit/Button';
import { DashboardContainer } from 'src/containers/DashboardContainer';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { fetchStoreFilteringProducts } from 'src/store/products/actions';
import { Loader } from 'src/components/Loader';
import { declOfNum, isEmpty } from 'src/utils';
import { ProductListCard } from 'src/components/kit/Cards/ProductListCard';
import { Modal } from 'src/components/kit/Modal';
import { TariffChoiceModal } from 'src/components/Modals/TariffChoiceModal';
import { useNavigate } from 'react-router-dom';
import { useTariff } from 'src/hooks/useTariff';
import { ProductType } from 'src/config/types';
import ProductService from 'src/services/ProductService';
import TariffService from 'src/services/TariffService';

export const StoreFiltering: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    user: { user },
    shops: { shops },
    products: { storeFilteringProducts, isLoadingStoreFilteringProducts },
    common: { globalSearch },
  } = useAppSelector(state => state);

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [productsToRemove, setProductsToRemove] = useState({});

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleRemove = (product: ProductType) => {
    setProductsToRemove(prev => {
      const arr = { ...prev };

      if (arr[product.shopId]) {
        arr[product.shopId] = [...arr[product.shopId], product.id.toString()];
      } else {
        arr[product.shopId] = [product.id.toString()];
      }
      return arr;
    });
  };

  const tariff = useTariff();

  const productsToRemoveCount = useMemo(() => {
    let allProductsCount = 0;
    let removedProductsCount = 0;

    shops.forEach(shop => {
      allProductsCount += storeFilteringProducts?.[shop.id]?.length || 0;
    });

    Object.keys(productsToRemove).forEach(key => {
      removedProductsCount += productsToRemove[key]?.length;
    });

    return allProductsCount - (tariff?.goodsCount || 0) - removedProductsCount;
  }, [tariff, shops, productsToRemove, storeFilteringProducts]);

  const handleSave = async () => {
    try {
      const requests: any[] = [];
      setIsLoading(true);

      for (const shopId in productsToRemove) {
        requests.push(ProductService.deleteProduct(productsToRemove[shopId].join(','), shopId));
      }

      await Promise.all(requests);

      if (!user?.subscriptionId) {
        await TariffService.changeUsersTariffFront({
          tariffId: 1,
          month: 1,
        });
      }

      setIsLoading(false);
      navigate('/');
      location.reload();
    } catch (e) {}
  };

  const filteredProducts = useMemo(() => {
    const res = {};

    Object.keys(storeFilteringProducts).map(key => {
      const elems = storeFilteringProducts[key].filter(prod =>
        prod.name?.toLowerCase()?.includes(globalSearch.toLowerCase()),
      );

      if (elems.length) {
        res[key] = elems;
      }
    });

    return res;
  }, [globalSearch, storeFilteringProducts]);

  useEffect(() => {
    (async () => {
      try {
        if (shops.length) {
          for (const shop of shops) {
            await dispatch(fetchStoreFilteringProducts(shop.id));
          }
        }
      } catch (e) {}
    })();
  }, [shops]);

  return (
    <DashboardContainer
      search
      noToolTip
      searchElement={
        <Button
          variant="turquoise"
          classNames="w-full t-lg:w-fit"
          onClick={handleSave}
          disabled={productsToRemoveCount > 0 || isLoading}
        >
          Сохранить
        </Button>
      }
      title={
        isLoadingStoreFilteringProducts ? (
          <Loader classNames="w-20" />
        ) : (
          <div className="whitespace-pre-wrap">
            Вам доступен тариф <div className="text-turquoise-100 inline">«{tariff?.name}»</div>. Вы
            можете загружать {tariff?.goodsCount} товаров.{`\n`}
            {productsToRemoveCount <= 0 ? null : (
              <>
                Чтобы продолжить пользоваться ботом, удалите{' '}
                <div className="text-turquoise-100 inline">
                  {productsToRemoveCount}{' '}
                  {declOfNum(productsToRemoveCount, ['товар', 'товара', 'товаров'])}
                </div>
                .
              </>
            )}
          </div>
        )
      }
      description={
        <div>
          После удаления товаров, нажмите кнопку сохранить и продолжайте пользоваться ботом в режиме
          «Лайт» либо выберите
          <div className="text-blue-100 cursor-pointer inline" onClick={handleOpen}>
            {' '}
            другой тариф
          </div>
        </div>
      }
    >
      {isLoadingStoreFilteringProducts ? (
        <Loader classNames="mx-auto my-20 w-1/2 t-lg:w-1/3" />
      ) : null}

      {!isLoadingStoreFilteringProducts && isEmpty(filteredProducts) && globalSearch ? (
        <div className="my-20 text-center text-xl t-sm:text-3xl font-gilroy-500">
          По вашему запросу ничего не найдено
        </div>
      ) : null}

      {!isLoadingStoreFilteringProducts && !isEmpty(filteredProducts)
        ? Object.keys(filteredProducts).map(shopId => {
            const shop = shops.find(shop => shop.id.toString() === shopId);
            const curProducts = filteredProducts[shopId];

            return (
              <div key={`store-filtering-shop-${shopId}`} className="w-full mb-6 t-lg:mb-10">
                {shop && curProducts ? (
                  <div className="mb-3 t-lg:mb-5 text-sm t-lg:text-lg font-gilroy-500">
                    {shop.name}
                  </div>
                ) : null}

                <div className="grid grid-cols-1 gap-5">
                  {curProducts.map((product, i) => {
                    if (productsToRemove?.[shopId]?.includes(product.id.toString())) {
                      return <Fragment key={`store-filtering-product-remove-${product.id}-${i}`} />;
                    }

                    return (
                      <ProductListCard
                        onRemove={handleRemove}
                        key={`store-filtering-product-${product.id}-${i}`}
                        data={product}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })
        : null}

      {openModal && tariff ? (
        <Modal isOpen={openModal} onClose={handleClose} mobileClose>
          <TariffChoiceModal initialTariff={tariff} />
        </Modal>
      ) : null}
    </DashboardContainer>
  );
};
