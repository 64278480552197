import { FC, useEffect, useRef } from 'react';
import { getDateToSupportChat } from 'src/utils';
import { UserLogo } from 'src/components/UserLogo';
import { SupportIncidentType, SupportMessageType } from 'src/config/types';
import parse from 'html-react-parser';

import { ReactComponent as CheckIcon } from 'src/assets/icons/kit/check.svg';
import { ReactComponent as ArrowBigIcon } from 'src/assets/icons/kit/arrow-big.svg';
import logoPath from 'src/assets/icons/logo.svg';

import cn from 'classnames';

interface MessageProps {
  message: SupportMessageType;
  withObserver: boolean;
  loadMore: () => void;
  selectedIncident: SupportIncidentType;
  classNames?: string;
}

export const Message: FC<MessageProps> = ({
  message,
  withObserver,
  loadMore,
  selectedIncident,
  classNames,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isMy = message?.author?.type === 'Bot';

  const observer = withObserver
    ? new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          handleLoadMore();
        }
      })
    : null;

  const handleLoadMore = () => {
    if (observer && withObserver && ref?.current) {
      observer.unobserve(ref.current);
      loadMore();
    }
  };

  useEffect(() => {
    if (observer && withObserver && ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (observer && withObserver && ref?.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      className={cn({ 'ml-auto flex-row-reverse': !isMy }, 'flex max-w-[80%] gap-2.5', classNames)}
      ref={ref}
    >
      <UserLogo
        classNames={cn(
          {
            'bg-turquoise-100': !Boolean(selectedIncident?.user?.image && !isMy),
            'bg-white': Boolean(selectedIncident?.user?.image) || isMy,
          },
          'w-12 h-12 min-w-[3rem]',
        )}
        image={isMy ? logoPath : selectedIncident?.user?.image}
        name={!isMy ? selectedIncident?.user?.name : ''}
      />

      <div className="flex flex-col gap-1">
        <div className={cn({ 'ml-auto': !isMy }, 'flex gap-2 items-center')}>
          <div className="font-gilroy-600 text-grey-100 opacity-50 text-small">
            {getDateToSupportChat(message?.date)}
          </div>
          {!isMy ? <CheckIcon className="w-2.4 h-2.5 stroke-path-green" /> : null}
        </div>

        <div
          className={cn(
            { 'bg-white rounded-tl-xl': !isMy, 'bg-white-100 rounded-tr-xl': isMy },
            'p-2.5 t-lg:p-4 rounded-b-xl text-grey-600 font-gilroy-500 text-xs t-lg:text-base',
          )}
        >
          {message?.media?.isImage && message?.media?.file ? (
            <a href={message?.media?.file} download target="_blank">
              <img src={message?.media?.file} alt="" className="w-auto h-auto" />
            </a>
          ) : null}

          {!message?.media?.isImage && message?.media?.file ? (
            <div className="flex items-center gap-3">
              <a
                href={message?.media?.file}
                download
                target="_blank"
                className={
                  'w-8 h-8 rounded-full bg-turquoise-100 flex items-center ' +
                  'justify-center cursor-pointer'
                }
              >
                <ArrowBigIcon className="fill-white w-4 h-4 rotate-90" />
              </a>
              <div>
                {message?.media?.file.slice(
                  message?.media?.file.lastIndexOf('/') + 1,
                  message?.media?.file.length,
                )}
              </div>
            </div>
          ) : null}

          {message?.message ? parse(message.message) : null}
        </div>
      </div>
    </div>
  );
};
