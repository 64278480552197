import { FC, useMemo, useRef, useState } from 'react';
import { ControlledInput } from 'src/components/kit/ControlledInput';
import { SupportUserCard } from 'src/components/Cards/SupportUserCard';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { Loader } from 'src/components/Loader';
import { SupportIncidentType } from 'src/config/types';

interface SupportUsersListProps {
  loadingIncidents: boolean;
  incidents: SupportIncidentType[];
  selectedIncident: SupportIncidentType | null;
  handleSelectIncident: (incident: SupportIncidentType | null) => void;
}

export const SupportUsersList: FC<SupportUsersListProps> = ({
  loadingIncidents,
  incidents,
  selectedIncident,
  handleSelectIncident,
}) => {
  const { width } = useWindowDimensions();
  const ref = useRef<HTMLDivElement | null>(null);
  const [search, setSearch] = useState<string>('');

  const filteredIncidents = useMemo(() => {
    const sortedIncidents = [...incidents]?.sort(
      (a, b) =>
        new Date(b?.lastMessage?.date)?.getTime() - new Date(a?.lastMessage?.date)?.getTime(),
    );

    if (!search) {
      return sortedIncidents;
    }

    return sortedIncidents.filter(incident =>
      incident.user.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [incidents, search]);

  return width >= 1024 || !selectedIncident ? (
    <div className="h-full t-lg:min-w-[24rem] t-lg:w-96 w-full t-lg:bg-white relative z-10">
      <div className="t-lg:px-8 py-2.5 px-4" ref={ref}>
        <ControlledInput classNames="w-full mb-2" onChange={setSearch} placeholder="Поиск" />
      </div>

      <div className="w-full overflow-auto t-lg:px-8 px-4" style={{ height: `calc(100% - 80px)` }}>
        {loadingIncidents ? <Loader classNames="mx-auto my-20 w-1/2 t-lg:w-full" /> : null}

        {!loadingIncidents && !incidents.length ? (
          <div className="my-20 text-center text-xl font-gilroy-500">
            У вас нет активных заказов
          </div>
        ) : null}

        {!loadingIncidents && incidents.length && !filteredIncidents.length && search ? (
          <div className="my-20 text-center text-xl font-gilroy-500">
            По вашему запросу ничего не найдено
          </div>
        ) : null}

        {!loadingIncidents && filteredIncidents.length
          ? filteredIncidents.map((incident, i) => (
              <SupportUserCard
                active={incident.incidentId === selectedIncident?.incidentId}
                onClick={handleSelectIncident}
                incident={incident}
                key={`support-user-${i}`}
                classNames="border-b border-grey-100"
              />
            ))
          : null}
      </div>
    </div>
  ) : null;
};
