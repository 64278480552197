import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import TokenService from 'src/services/TokenService';

interface PublicRouteProps {
  children: ReactNode;
}

export const PublicRoute: FC<PublicRouteProps> = ({ children }) => {
  const user = TokenService.getUser();

  if (user) {
    return <Navigate to="/dashboard/shops" />;
  }

  return <>{children}</>;
};
