import { createAsyncThunk } from '@reduxjs/toolkit';
import GroupService from 'src/services/GroupService';

export const fetchGroups = createAsyncThunk('groups/fetch', async (shopId: string, thunkAPI) => {
  try {
    const response = await GroupService.getGroups(shopId);

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить группы');
  }
});
