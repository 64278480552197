import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/hooks/useRedux';
import { TariffType } from 'src/config/types';

export const useTariff = () => {
  const { tariffs, user } = useAppSelector(state => state.user);
  const [tariff, setTariff] = useState<TariffType>();

  useEffect(() => {
    setTariff(tariffs?.find(el => el.id.toString() === (user?.subscriptionId?.toString() || '1')));
  }, [tariffs, user]);

  return tariff;
};
