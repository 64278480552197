import { FC } from 'react';
import cn from 'classnames';

interface TitleProps {
  title?: string;
  description?: string;
  classNames?: string;
}

export const Title: FC<TitleProps> = ({ title, description, classNames }) => {
  return (
    <div className={cn('w-full flex flex-col gap-2.5', classNames)}>
      {title ? <div className="t-sm:text-3xl text-xl font-gilroy-500">{title}</div> : null}
      {description ? (
        <div className="font-gilroy-400 t-sm:text-base text-xs text-grey-100">{description}</div>
      ) : null}
    </div>
  );
};
