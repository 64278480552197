import { FC, useState } from 'react';
import { Input } from 'src/components/kit/Input';
import { AuthFormContainer } from 'src/containers/AuthFormContainer';
import { useForm } from 'react-hook-form';
import { AppLink } from 'src/components/kit/AppLink';
import { Button } from 'src/components/kit/Button';
import { useAppDispatch } from 'src/hooks/useRedux';
import { setGlobalError } from 'src/store/common';
import { EMAIL_VALIDATION_REGEXP } from 'src/config/regExp';
import UserService from 'src/services/UserService';

export const ResetPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = handleSubmit(async data => {
    const { email } = data;
    setLoading(true);
    try {
      await UserService.resetPasswordLink(email)
        .then(() => {
          dispatch(
            setGlobalError(
              'На Вашу почту отправлено письмо, содержащее ссылку для восстановления пароля',
            ),
          );
        })
        .catch(({ response }) => {
          dispatch(setGlobalError(response.data));
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  });

  return (
    <AuthFormContainer
      onSubmit={onSubmit}
      title="Введите ваш E-mail чтобы сбросить пароль"
      classNames="justify-center"
    >
      <div className="flex flex-col gap-5 w-full">
        <Input
          register={register}
          data={{ placeholder: 'Email', name: 'email', required: true }}
          errors={errors}
          registerOptions={{
            validate: (val: string) => {
              if (!EMAIL_VALIDATION_REGEXP.test(val)) {
                return 'Некорректный email';
              }
            },
          }}
        />
      </div>
      <Button disabled={loading} variant="turquoise" type="submit">
        Сбросить пароль
      </Button>

      <AppLink href="/">Отмена</AppLink>
    </AuthFormContainer>
  );
};
