import { ShopApi } from 'src/services/Api/ShopApi';

const createGroup = (name: string, shopId: string) => {
  return ShopApi.post(`/Catalog/CreateGroup?name=${name}&shopId=${shopId}`);
};

const getGroups = (shopId: string) => {
  return ShopApi.post(`/Catalog/GetGroupList?shopId=${shopId}`);
};

const updateGroup = (id: string, name: string, shopId: string) => {
  return ShopApi.post(`/Catalog/UpdateGroup?id=${id}&shopId=${shopId}&name=${name}`);
};

const deleteGroup = (id: string, shopId: string) => {
  return ShopApi.post(`/Catalog/DeleteGroup?id=${id}&shopId=${shopId}`);
};

const exportExel = (shopId: string) => {
  return ShopApi.get(`/Catalog/Export?shopId=${shopId}`);
};

const importExel = (shopId: string, url: string) => {
  const data = JSON.stringify({ shopId, url });

  return ShopApi.post(`/Catalog/Import`, data);
};

const GroupService = { createGroup, getGroups, updateGroup, deleteGroup, exportExel, importExel };

export default GroupService;
