import { ShopApi } from 'src/services/Api/ShopApi';
import { LocalProductType, ProductType, StatType } from 'src/config/types';

const createProduct = (params: LocalProductType | ProductType) => {
  return ShopApi.post('/Catalog/ImportGood', params);
};

const getProducts = (shopId: string, groupIds?: string) => {
  return ShopApi.post(`/Catalog/GetGoodList?shopId=${shopId}&groupIds=${groupIds}`);
};

const updateProduct = (params: LocalProductType | ProductType) => {
  return ShopApi.post(`/Catalog/UpdateGood`, params);
};

const deleteProduct = (id: string, shopId: string) => {
  return ShopApi.post(`/Catalog/DeleteGood?Ids=${id}&shopId=${shopId}`);
};

const addStats = (params: StatType[]) => {
  return ShopApi.post(`/Catalog/UniversalParametersValues`, params);
};

const getCurrency = () => {
  return ShopApi.post('/Catalog/GetCurrency');
};

const ProductService = {
  createProduct,
  getProducts,
  updateProduct,
  deleteProduct,
  addStats,
  getCurrency,
};

export default ProductService;
