import { FC } from 'react';
import { getDateToSupportChat } from 'src/utils';
import { UserLogo } from 'src/components/UserLogo';
import { AppToolTip } from 'src/components/AppToolTip';
import { SupportIncidentType } from 'src/config/types';

import cn from 'classnames';

interface SupportUserCardProps {
  incident: SupportIncidentType;
  onClick: (user: SupportIncidentType) => void;
  active: boolean;
  classNames?: string;
}

export const SupportUserCard: FC<SupportUserCardProps> = ({
  incident,
  onClick,
  active,
  classNames,
}) => {
  const handleClick = () => onClick(incident);

  return (
    <div
      className={cn(
        { 'bg-white-100': active },
        'w-full py-4 cursor-pointer hover:bg-white-100 flex gap-2.5 transition-full',
        classNames,
      )}
      onClick={handleClick}
    >
      <UserLogo
        image={incident?.user?.image}
        name={incident?.user?.name}
        classNames={cn(
          { 'bg-turquoise-100': !Boolean(incident?.user?.image) },
          'w-11 h-11 min-w-[2.75rem]',
        )}
      />

      <div className="w-2/3">
        {incident?.user?.name ? (
          <>
            <AppToolTip
              classNames="w-full truncate font-gilroy-500 text-xl mb-0.5"
              id={`support-user-name-${incident.incidentId}-tooltip`}
              text={incident?.user?.name}
            />
          </>
        ) : null}
        {incident?.lastMessage?.message ? (
          <>
            <div className="w-full truncate font-gilroy-400 text-sm text-grey-100">
              {incident?.lastMessage?.message}
            </div>
          </>
        ) : null}
      </div>

      <div className="ml-auto flex flex-col gap-4 items-center">
        {incident?.lastMessage?.date ? (
          <div className="font-gilroy-400 text-small text-grey-100 whitespace-nowrap">
            {getDateToSupportChat(incident?.lastMessage?.date)}
          </div>
        ) : null}
        {incident?.unreadMessagesCount ? (
          <div
            className={
              'rounded-full flex items-center justify-center w-4 h-4 ' +
              'bg-turquoise-100 text-white font-gilroy-600 text-extra-small-1'
            }
          >
            {incident?.unreadMessagesCount}
          </div>
        ) : null}
      </div>
    </div>
  );
};
