import { FC, useEffect, useMemo, useState } from 'react';
import { Select } from 'src/components/kit/Select';
import { ColorPicker } from 'src/components/kit/ColorPicker';
import { noGrid } from 'src/utils';
import { defaultShopColors } from 'src/config/data';
import { useAppSelector } from 'src/hooks/useRedux';
import { PresetType, SelectItemType, ShopType } from 'src/config/types';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';

import { ReactComponent as EditIcon } from 'src/assets/icons/kit/edit.svg';

interface ShopColorsProps {
  defaultValues?: ShopType;
  errors: FieldErrors;
  setValue: UseFormSetValue<any>;
  clearErrors: (name?: any) => void;
  register: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
}

export const ShopColors: FC<ShopColorsProps> = ({
  defaultValues,
  errors,
  setValue,
  clearErrors,
  register,
}) => {
  const { presets, isLoadingPresets } = useAppSelector(state => state.shops);

  const [isOpenPresetEdit, setIsOpenPresetEdit] = useState<boolean>(false);
  const [selectedPreset, setSelectedPreset] = useState<PresetType>();

  const handleOpenPresetEdit = () => setIsOpenPresetEdit(prev => !prev);

  const handleChangePreset = (selectItem: SelectItemType) => {
    const newPreset = presets.find(el => el.id.toString() === selectItem.value);
    if (!newPreset) return;

    setSelectedPreset(newPreset);
    setValue('buttonsColor', noGrid(newPreset.buttons));
    setValue('textColor', noGrid(newPreset.text));
    setValue('color', noGrid(newPreset.background));
    setValue('discountColor', noGrid(newPreset.discount));
  };

  useEffect(() => {
    if (!isLoadingPresets && presets?.length) {
      const curPres = presets.find(
        preset =>
          preset.text === defaultValues?.textColor &&
          preset.background === defaultValues?.color &&
          preset.buttons === defaultValues?.buttonsColor &&
          preset.discount === defaultValues?.discountColor,
      );

      if (curPres) {
        setSelectedPreset(curPres);
      } else {
        setSelectedPreset({
          id: 0,
          name: 'Своя',
          background: defaultValues?.color || '',
          buttons: defaultValues?.buttonsColor || '',
          text: defaultValues?.textColor || '',
          discount: defaultValues?.discountColor || '',
        });
      }
    }
  }, [isLoadingPresets]);

  const colorsName = ['background', 'buttons', 'discount', 'text'];

  const generateColors = (preset: PresetType) => (
    <div
      key={`preset-colors-${preset.id}`}
      className={
        'flex w-12 min-w-[3rem] t-sm:w-fit t-sm:min-w-fit flex-wrap gap-1 t-sm:gap-2 ' +
        'ml-auto px-2 t-sm:mr-6 t-sm:flex-nowrap'
      }
    >
      {colorsName.map(colorType => (
        <div
          className="w-3 h-3 t-sm:w-7 t-sm:h-7 t-sm:min-w-[1.75rem] rounded-full"
          style={{ background: preset[colorType] }}
          key={`color-type-${colorType}`}
        />
      ))}
    </div>
  );

  const presetsList: SelectItemType[] = useMemo(
    () =>
      presets?.map(el => ({
        name: el.name,
        value: el.id.toString(),
        rightComponent: generateColors(el),
      })),
    [presets],
  );

  const colors = [
    {
      name: 'color',
      title: 'Цвет фона',
      value: defaultValues?.color || defaultShopColors.color,
    },
    {
      name: 'buttonsColor',
      title: 'Цвет Кнопок',
      value: defaultValues?.buttonsColor || defaultShopColors.buttonsColor,
    },
    {
      name: 'textColor',
      title: 'Цвет текста кнопок',
      value: defaultValues?.textColor || defaultShopColors.textColor,
    },
    {
      name: 'discountColor',
      title: 'Цвет текста скидки',
      value: defaultValues?.discountColor || defaultShopColors.discountColor,
    },
  ];

  return (
    <>
      <div className="w-full" id="shop-colors-onboarding">
        <div className="font-gilroy-400 text-sm mb-2.5 flex items-center gap-1">Цветовая тема</div>

        <div className="w-full flex">
          <Select
            data={{
              placeholder: 'Цветовая тема',
              name: 'preset',
              list: presetsList,
            }}
            setStateValue={handleChangePreset}
            value={
              selectedPreset
                ? {
                    name: selectedPreset.name,
                    value: selectedPreset.id.toString(),
                    rightComponent: generateColors(selectedPreset),
                  }
                : presetsList[0]
            }
          />
          <div
            className={
              'cursor-pointer ml-2 t-sm:ml-5 flex items-center justify-center w-[3.25rem] min-w-[3.25rem] ' +
              'square rounded-xl bg-white-200 shadow-black-700'
            }
            onClick={handleOpenPresetEdit}
          >
            <EditIcon className="w-5 h-5 fill-grey-200" />
          </div>
        </div>
      </div>

      {isOpenPresetEdit ? (
        <div className="w-full flex flex-col gap-5 items-center" id="shop-colors-onboarding">
          {colors.map(input => (
            <div className="w-full" key={input.name}>
              <div className="font-gilroy-400 text-sm mb-2.5">{input.title}</div>
              <ColorPicker
                selectedPreset={selectedPreset}
                setSelectedPreset={setSelectedPreset}
                defaultValue={noGrid(input?.value)}
                setValue={setValue}
                register={register}
                clearErrors={clearErrors}
                data={{ placeholder: 'Введите код', name: input.name, required: true }}
                errors={errors}
              />
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};
