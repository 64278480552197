import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { MainContainer } from 'src/containers/MainContainer';
import { ProtectedRoute } from 'src/routes/ProtectedRoute';
import { protectedRoutes, routes } from 'src/routes';
import { PublicRoute } from 'src/routes/PublicRoute';
import { setupStore } from 'src/store/store';
import { Provider } from 'react-redux';

export const App: FC = () => {
  const store = setupStore();

  return (
    <>
      <Provider store={store}>
        <MainContainer>
          <Routes>
            {routes.map(route => (
              <Route
                key={`route-${route.path}`}
                path={route.path}
                element={<PublicRoute>{route.element}</PublicRoute>}
              />
            ))}
          </Routes>

          <Routes>
            {protectedRoutes.map(route => (
              <Route
                key={`protected-route-${route.path}`}
                path={route.path}
                element={<ProtectedRoute>{route.element}</ProtectedRoute>}
              />
            ))}
          </Routes>
        </MainContainer>
      </Provider>
    </>
  );
};
