import { FC, useEffect, useState } from 'react';
import { Button } from 'src/components/kit/Button';
import { fetchUser } from 'src/store/user/actions';
import { Loader } from 'src/components/Loader';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import PaymentService from 'src/services/PaymentService';

import successGif from 'src/assets/gifs/payment-success.gif';
import waitingGif from 'src/assets/gifs/payment-waiting.gif';

export const Processing: FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tariffId = urlParams.get('tariffId');
  const duration = urlParams.get('duration');
  const orderId = urlParams.get('orderId');

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);

  const goHome = () => {
    window.location.replace('/');
  };

  if (!tariffId || !duration) goHome();

  const {
    user: { tariffs, isLoadingTariffs },
  } = useAppSelector(state => state);

  const curTariff = tariffs.find(tariff => tariff.id.toString() === tariffId?.toString());

  if (!isLoadingTariffs && !curTariff) goHome();

  // const getDate = () => {
  //   if (!duration) {
  //     return '';
  //   }
  //
  //   const curDate =
  //     curTariff?.id === user?.subscriptionId && user?.subscriptionEnd
  //       ? new Date(user?.subscriptionEnd)
  //       : new Date();
  //
  //   const newDate = new Date(curDate.setMonth(curDate.getMonth() + +duration));
  //
  //   return `${newDate.getDate()} ${monthsNames[newDate.getMonth()]} ${newDate.getFullYear()}`;
  // };

  useEffect(() => {
    (async () => {
      if (!isLoadingTariffs && curTariff && tariffId && duration && orderId) {
        try {
          const res = await PaymentService.checkPayment(orderId);

          if (res.data.status === 'CONFIRMED') {
            await dispatch(fetchUser());
            setLoading(false);
          }
        } catch (e) {}
      }
    })();
  }, [isLoadingTariffs, curTariff]);

  const styles = 'inline text-turquoise-100 mx-1';

  return (
    <div className="w-full h-full flex items-center justify-center px-5">
      {isLoadingTariffs ? <Loader classNames="w-1/3" /> : null}

      {!isLoadingTariffs && curTariff ? (
        <div className="max-w-[42rem] flex flex-col items-center">
          <div className="w-1/3">
            <img src={loading ? waitingGif : successGif} alt="" className="w-full h-full" />
          </div>

          <div className="mb-5 font-gilroy-500 text-xl t-lg:text-3xl text-center">
            {loading ? 'Подождите пару минут...' : 'Успешная оплата'}
          </div>
          <div className="text-center text-grey-100 text-sm t-lg:text-xl mb-20 whitespace-pre-wrap">
            {loading
              ? `Ожидается оплата для тарифа “Профи”.\nВы сможете загружать`
              : `Спасибо за оплату.\nТеперь вы можете загружать`}
            <div className={styles}>{curTariff.goodsCount}</div>
            товаров.
            {/*Тариф будет активирован до <div className={styles}>{getDate()}</div>*/}
          </div>
          <Button href="/" variant="turquoise-outline">
            Вернуться в магазин
          </Button>
        </div>
      ) : null}
    </div>
  );
};
