import { Api } from 'src/services/Api/Api';

const getSteps = () => {
  return Api.post(`/Auth/GetSteps`);
};

const createUsersStep = (params: { onboardingStepId: number; buttonId: number }) => {
  return Api.post(`/Auth/CreateUsersStep`, params);
};

const resetSteps = () => {
  return Api.post(`/Auth/ResetOnboarding`);
};

const OnBoardingService = {
  getSteps,
  createUsersStep,
  resetSteps,
};

export default OnBoardingService;
