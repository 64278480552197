import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupsState } from './types';
import { fetchGroups } from './actions';
import { GroupType } from 'src/config/types';

const initialState: GroupsState = {
  groups: [],
  isLoadingGroups: true,
  error: '',
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGroups.pending.type]: state => {
      state.isLoadingGroups = true;
      state.groups = [];
    },
    [fetchGroups.fulfilled.type]: (state, action: PayloadAction<GroupType[]>) => {
      state.isLoadingGroups = false;
      state.groups = action.payload;
      state.error = '';
    },
    [fetchGroups.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingGroups = false;
      state.error = action.payload;
    },
  },
});

export const {} = groupsSlice.actions;

const reducer = groupsSlice.reducer;
export default reducer;
