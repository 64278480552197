import { FC } from 'react';
import { AppToolTip } from 'src/components/AppToolTip';
import { TabType } from 'src/config/types';

import cn from 'classnames';

interface TagProps {
  onClick: (tag: TabType) => void;
  tag: TabType;
  isActive?: boolean;
  classNames?: string;
}

export const Tag: FC<TagProps> = ({ tag, onClick, isActive = false, classNames }) => {
  const handleClick = () => {
    onClick(tag);
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={cn(
          {
            'bg-white border-turquoise-100 text-turquoise-100 cursor-default': isActive,
            'bg-white-100 border-white-100 text-grey-100 cursor-pointer': !isActive,
            'hover:shadow-turquoise-100': !isActive,
          },
          'border py-1.5 px-2.5 rounded-full font-gilroy-500 text-xs whitespace-nowrap',
          classNames,
        )}
      >
        <AppToolTip classNames="truncate" id={`tag-name-${tag.value}-tooltip`} text={tag.name} />
      </div>
    </>
  );
};
