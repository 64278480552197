const getLocalRefreshToken = () => {
  const data = localStorage.getItem('user');

  if (!data) {
    return null;
  }
  const user = JSON.parse(data);

  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  const data = localStorage.getItem('user');

  if (!data) {
    return null;
  }

  const user = JSON.parse(data);
  return user?.token;
};

const getLocalSessionId = () => {
  const data = localStorage.getItem('user');

  if (!data) {
    return null;
  }

  const user = JSON.parse(data);
  return user?.sessionId;
};

const getUser = () => {
  const data = localStorage.getItem('user');

  if (!data) {
    return null;
  }
  return JSON.parse(data);
};

const setUser = user => {
  const tokens = {
    token: getLocalAccessToken(),
    refreshToken: getLocalRefreshToken(),
    sessionId: getLocalSessionId(),
  };
  localStorage.setItem('user', JSON.stringify({ ...user, ...tokens }));
};

const setTokens = tokens => {
  const user = getUser();
  localStorage.setItem('user', JSON.stringify({ ...user, ...tokens }));
};

const removeUser = () => {
  localStorage.setItem('user', '');
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  getLocalSessionId,
  getUser,
  setUser,
  setTokens,
  removeUser,
};

export default TokenService;
