import { FC, MouseEvent, ReactNode } from 'react';
import { ButtonVariantsType } from 'src/config/types';
import { Link } from 'react-router-dom';

import cn from 'classnames';

interface ButtonProps {
  onClick?: (e?: MouseEvent) => void;
  href?: string;
  newWindow?: boolean;
  variant: ButtonVariantsType;
  icon?: ReactNode;
  size?: 'base' | 'small' | 'icon';
  classNames?: string;
  text?: string;
  children?: ReactNode;
  disabled?: boolean;
  id?: string;
  buttonVariant?: 'button' | 'link';
  type?: 'submit' | 'reset' | 'button' | undefined;
}

export const Button: FC<ButtonProps> = ({
  onClick,
  variant,
  size = 'base',
  icon,
  id,
  type,
  classNames,
  text,
  children,
  disabled,
  href,
  buttonVariant,
  newWindow,
}) => {
  const styles: { [key in ButtonVariantsType]: string } = {
    turquoise: 'btn-turquoise',
    'turquoise-outline': 'btn-turquoise-outline',
    'turquoise-light': 'btn-turquoise-light',
    'turquoise-icon': 'btn-turquoise-icon',
    grey: 'btn-grey',
    green: 'btn-green',
  };

  const classes = cn(
    {
      'text-xl px-9 py-3': size === 'base',
      'text-xs px-5 py-2': size === 'small',
      'p-2': size === 'icon',
    },
    'btn flex items-center justify-center',
    styles[variant],
    classNames,
  );

  const handleClick = (e: MouseEvent) => {
    onClick && onClick(e);
    if (buttonVariant === 'link') {
      return;
    }

    if (href) {
      if (newWindow) {
        window.open(href, '_blank');
      }
      if (!newWindow) {
        window.location.replace(href);
      }
    }
  };

  return buttonVariant === 'link' && href ? (
    <Link to={href} id={id} onClick={handleClick} className={classes} type={type}>
      {text ? text : null}
      {children && !text ? children : null}
      {icon ? <div className="w-4 h-4">{icon}</div> : null}
    </Link>
  ) : (
    <button id={id} onClick={handleClick} className={classes} type={type} disabled={disabled}>
      {text ? text : null}
      {children && !text ? children : null}
      {icon ? <div className="w-4 h-4">{icon}</div> : null}
    </button>
  );
};
