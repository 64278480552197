import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface GradientBorderProps {
  borderWidth: string;
  borderGradient: string;
  classNames?: string;
  children?: ReactNode;
}

export const GradientBorder: FC<GradientBorderProps> = ({
  classNames,
  borderGradient,
  borderWidth,
  children,
}) => {
  return (
    <div
      className={cn(
        'z-10 bg-clip-padding relative border border-transparent border-solid',
        classNames,
      )}
      style={{ borderWidth }}
    >
      <div
        className={cn('z-10 absolute inset-0 rounded-inherit', borderGradient)}
        style={{ margin: `-${borderWidth}` }}
      />
      <div className="z-20 relative w-full h-full">{children}</div>
    </div>
  );
};
