import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

interface AppLinkProps {
  href: string;
  classNames?: string;
  children?: ReactNode;
}

export const AppLink: FC<AppLinkProps> = ({ href, classNames, children }) => {
  return (
    <Link to={href} className={cn('cursor-pointer text-blue-100 font-gilroy-500', classNames)}>
      {children}
    </Link>
  );
};
