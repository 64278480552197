import { FC } from 'react';
import { Button } from 'src/components/kit/Button';

interface DeleteModalProps {
  text: string;
  removeText?: string;
  handleDelete: () => void;
  handleClose: () => void;
}

export const DeleteModal: FC<DeleteModalProps> = ({
  text,
  removeText,
  handleDelete,
  handleClose,
}) => {
  return (
    <div className="flex flex-col h-full justify-center w-full items-center">
      <div className="mb-20 font-gilroy-500 text-xl text-center whitespace-pre-wrap">{text}</div>
      <div className="flex w-full flex-col justify-center t-sm:flex-row gap-2.5 t-sm:gap-5">
        <Button variant="turquoise-outline" classNames="w-full t-lg:w-fit" onClick={handleDelete}>
          {removeText || 'Удалить'}
        </Button>
        <Button variant="turquoise" classNames="w-full t-lg:w-fit" onClick={handleClose}>
          Отмена
        </Button>
      </div>
    </div>
  );
};
