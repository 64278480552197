import { Api } from 'src/services/Api/Api';

const getTariffs = () => {
  return Api.get('/Auth/getSubscriptions');
};

const changeUsersTariff = (params: { tariffId: number; month: number }) => {
  return Api.post(`/Auth/changeUsersTariff`, params);
};

const changeUsersTariffFront = (params: { tariffId: number; month: number }) => {
  return Api.post(`/Auth/changeUsersTariffFront`, params);
};

const TariffService = { getTariffs, changeUsersTariff, changeUsersTariffFront };

export default TariffService;
