import { Api } from 'src/services/Api/Api';
import { setGlobalError } from 'src/store/common';
import { setUser } from 'src/store/user';
import { AppDispatch } from 'src/store/store';
import { RegisterType } from 'src/config/types';
import TokenService from 'src/services/TokenService';
import UserService from 'src/services/UserService';

export const register = (data: RegisterType, dispatch: AppDispatch) =>
  new Promise<void>(async (resolve, reject) => {
    await Api.post('/Auth/register', data)
      .then(async () => {
        await login(data.login, data.password, dispatch)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      })
      .catch(({ response }) => {
        dispatch(setGlobalError(`Ошибка!\n${response.data}`));
        reject();
      });
  });

export const login = (login: string, password: string, dispatch: AppDispatch) =>
  new Promise<void>(async (resolve, reject) => {
    const data = JSON.stringify({
      login,
      password,
    });

    await Api.post('/Auth/login', data)
      .then(async response => {
        await TokenService.setTokens(response.data);
        await UserService.getCurrentUserFromApi().then(res => {
          TokenService.setUser(res.data);
          const user = { ...response.data, ...res.data };
          dispatch(setUser(user));
          resolve();
        });
      })
      .catch(({ response }) => {
        dispatch(setGlobalError(`Ошибка!\n${response?.data?.error}`));
        reject();
      });
  });

const refreshToken = () => {
  return Api.post('/Auth/refresh', {
    refreshToken: TokenService.getLocalRefreshToken(),
    sessionId: TokenService.getLocalSessionId(),
  }).then(response => {
    if (response.data) {
      TokenService.setTokens(response.data);
    }
  });
};

const logout = () => {
  return Api.post('/Auth/logout', {
    sessionId: TokenService.getLocalSessionId(),
  });
};

const AuthService = {
  register,
  login,
  refreshToken,
  logout,
};

export default AuthService;
