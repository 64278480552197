import { FC, useEffect, useState } from 'react';
import { Input } from 'src/components/kit/Input';
import { InputFile } from 'src/components/kit/InputFile';
import { Button } from 'src/components/kit/Button';
import { useForm } from 'react-hook-form';
import { noGrid, toBase64 } from 'src/utils';
import { useAppDispatch } from 'src/hooks/useRedux';
import { fetchPresets, fetchShops } from 'src/store/shops/actions';
import { setGlobalError } from 'src/store/common';
import { OnBoarding } from 'src/components/OnBoarding';
import { ShopColors } from 'src/components/ShopColors';
import { LocalShopType, ShopType } from 'src/config/types';
import { defaultShopColors } from 'src/config/data';
import { createShopOnBoardingState } from 'src/config/onBoardingData';
import ShopService from 'src/services/ShopService';

import { ReactComponent as TgIcon } from 'src/assets/icons/kit/tg.svg';
import { ReactComponent as ChatIcon } from 'src/assets/icons/kit/chat.svg';

import cn from 'classnames';

interface CreateShopFormProps {
  defaultValues?: ShopType;
  onSave?: () => void;
  classNames?: string;
}

export const CreateShopForm: FC<CreateShopFormProps> = ({ defaultValues, onSave, classNames }) => {
  const getColor = (colorName: string) =>
    noGrid(defaultValues?.[colorName]) || defaultShopColors?.[colorName] || '';

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<LocalShopType>({
    mode: 'all',
    defaultValues: {
      ...defaultValues,
      color: getColor('color'),
      buttonsColor: getColor('buttonsColor'),
      textColor: getColor('textColor'),
      discountColor: getColor('discountColor'),
    },
  });

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await dispatch(fetchPresets());
    })();
  }, []);

  const onSubmit = handleSubmit(async data => {
    const { image, color, buttonsColor, textColor, discountColor } = data;

    setLoading(true);

    let imageSrc = '';

    if (typeof image === 'string' && image) {
      imageSrc = image;
    }
    if (typeof image !== 'string' && image && (image as File)?.name) {
      imageSrc = (await toBase64(image)) as string;
    }

    const shop: LocalShopType = {
      ...(imageSrc && { image: imageSrc }),
      orderChatId: data.orderChatId,
      token: data.token,
      name: data.name,
      description: data.description,
      color: noGrid(color),
      buttonsColor: noGrid(buttonsColor),
      textColor: noGrid(textColor),
      discountColor: noGrid(discountColor),
    };

    if (defaultValues) {
      await ShopService.updateShop(shop, defaultValues.id)
        .then(async () => {
          if (onSave) onSave();
          await dispatch(fetchShops());
        })
        .catch(({ response }) => {
          dispatch(setGlobalError(`Ошибка!\n${response.data}`));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await ShopService.createShop(shop)
        .then(async () => {
          if (onSave) onSave();
          await dispatch(fetchShops());
        })
        .catch(({ response }) => {
          dispatch(setGlobalError(`Ошибка!\n${response.data}`));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  return (
    <form
      className={cn(
        'max-w-[32.5rem] w-full t-lg:w-[32.5rem] mx-auto flex flex-col gap-5 items-center',
        classNames,
      )}
      onSubmit={onSubmit}
    >
      <OnBoarding steps={createShopOnBoardingState} id={2} />

      <InputFile
        id="shop-logo-onboarding"
        variant="logo"
        clearErrors={clearErrors}
        defaultValue={defaultValues?.image}
        register={register}
        setValue={setValue}
        data={{ placeholder: '', name: 'image', required: false }}
        errors={errors}
      />

      <Input
        id="shop-name-onboarding"
        register={register}
        data={{ placeholder: 'Название', name: 'name', required: true }}
        errors={errors}
      />

      <Input
        id="shop-description-onboarding"
        type="textarea"
        register={register}
        data={{ placeholder: 'Описание', name: 'description', required: true }}
        errors={errors}
      />

      <ShopColors
        register={register}
        errors={errors}
        clearErrors={clearErrors}
        setValue={setValue}
        defaultValues={defaultValues}
      />

      <div className="w-full" id="shop-bot-token-onboarding">
        <div className="font-gilroy-400 text-sm mb-2.5 flex items-center gap-1">
          Токен магазина
          <TgIcon className="w-4 h-4 fill-grey-200" />
        </div>
        <Input
          register={register}
          data={{ placeholder: 'Токен', name: 'token', required: true }}
          errors={errors}
          toolTip={{
            text: 'Что такое токен магазина?',
            href: '/dashboard/faq',
          }}
        />
      </div>

      <div className="w-full" id="shop-chat-token-onboarding">
        <div className="font-gilroy-400 text-sm mb-2.5 flex items-center gap-1">
          ID чата с покупателем
          <ChatIcon className="w-4 h-4 fill-grey-200" />
        </div>
        <Input
          register={register}
          data={{ placeholder: 'ID', name: 'orderChatId', required: true }}
          errors={errors}
          toolTip={{
            text: 'Где взять ID чата?',
            href: '/dashboard/faq',
          }}
        />
      </div>

      <div className="flex t-sm:w-fit t-sm:flex-row-reverse flex-col w-full gap-5">
        <Button variant="turquoise" type="submit" disabled={loading}>
          Сохранить
        </Button>
        <Button
          variant="turquoise-outline"
          onClick={e => {
            e?.stopPropagation();
            onSave && onSave();
          }}
        >
          Отмена
        </Button>
      </div>
    </form>
  );
};
