import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Head } from 'src/components/Head';
import { Button } from 'src/components/kit/Button';
import { Modal } from 'src/components/kit/Modal';
import { setGlobalError, setIsSupportModalOpen } from 'src/store/common';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setUser } from 'src/store/user';
import { fetchShops } from 'src/store/shops/actions';
import { fetchOnBoarding, fetchTariffs, fetchUser } from 'src/store/user/actions';
import { SupportForm } from 'src/components/Forms/SupportForm';
import { TariffEndModal } from 'src/components/Modals/TariffEndModal';
import { RegisterVideos } from 'src/components/RegisterVideos';
import { fetchOrders } from 'src/store/orders/actions';
import { WS_SITE_LINK } from 'src/config';
import TokenService from 'src/services/TokenService';

import { ReactComponent as SupportIcon } from 'src/assets/icons/kit/support.svg';
import cn from 'classnames';

interface MainContainerProps {
  children?: ReactNode;
}

export const MainContainer: FC<MainContainerProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    orders: { ordersSort },
    shops: { selectedShop },
    user: { tariffs, user },
    common: { globalError, isSupportModalOpen },
  } = useAppSelector(state => state);

  const centerRoutes = ['', 'registration', 'reset-password', 'changepwd'];

  const isSupport = useMemo(() => location.pathname.includes('support'), [location]);
  const isPayment = useMemo(() => location.pathname.includes('payment'), [location]);
  const isRegister = useMemo(() => location.pathname.includes('registration'), [location]);
  const isStoreFiltering = useMemo(() => location.pathname.includes('store-filtering'), [location]);
  const isCenter = useMemo(
    () => centerRoutes.includes(location.pathname.replaceAll('/', '')),
    [location, isPayment],
  );
  useEffect(() => {
    (async () => {
      if (!tariffs?.length) {
        await dispatch(fetchTariffs());
      }
    })();
  }, [user]);

  useEffect(() => {
    const user = TokenService.getUser();

    if (!user) {
      return;
    }
    dispatch(setUser(user));

    (async () => {
      await dispatch(fetchShops());
      await dispatch(fetchUser());
      await dispatch(fetchOnBoarding());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedShop) {
        await dispatch(
          fetchOrders({
            shopId: selectedShop.id,
            direction: ordersSort,
          }),
        );
      }
    })();
  }, [selectedShop]);

  const closeModal = () => {
    dispatch(setGlobalError(''));
    window.location.reload();
  };

  const handleOpenSupport = () => dispatch(setIsSupportModalOpen(true));
  const handleCloseSupport = () => dispatch(setIsSupportModalOpen(false));

  const oferta = (
    <div
      className={
        'font-gilroy-400 text-sm w-fit mx-auto mt-auto py-5 text-black-100 px-5 text-center mt-auto'
      }
    >
      © 2023 Все права защищены. Платформа разработана ООО{' '}
      <a href={WS_SITE_LINK} target="_blank" className="inline text-blue-100 hover:underline">
        «ВИН СОЛЮШЕНС»
      </a>{' '}
      ИНН 2310217663, ОГРН 1192375085099
    </div>
  );

  return (
    <>
      {!isCenter ? (
        <>
          <Head isSupport={isSupport} onlyLogo={isPayment || isStoreFiltering} />
          <div
            className={cn({ flex: isSupport }, 'h-[100vh] pt-16 overflow-auto')}
            id="main-scroll"
          >
            <div
              className={cn(
                { 'h-full': isSupport || isPayment },
                'min-h-full max-w-[80rem] w-full mx-auto flex flex-col',
              )}
            >
              {children}
              {!isSupport ? oferta : null}
            </div>

            {isSupport ? (
              <div className="absolute bg-white top-0 right-[76%] h-full w-full hidden t-lg:block" />
            ) : null}

            {!isSupport && !isPayment ? (
              <div className="fixed w-full h-0 right-5 bottom-20 t-lg:bottom-28 z-20">
                <div className="max-w-[80rem] h-0 w-full flex mx-auto">
                  <div
                    className={
                      'w-16 h-16 t-lg:w-24 t-lg:h-24 bg-gradient-blue-100 shadow-turquoise-100 ' +
                      'rounded-full flex items-center justify-center cursor-pointer ml-auto'
                    }
                    id="support-button-onboarding"
                    onClick={handleOpenSupport}
                  >
                    <SupportIcon className="w-8 t-lg:w-10 h-8 t-lg:h-10 fill-white" />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}

      {isCenter ? (
        <>
          <div className={cn({ 'grid t-lg:grid-cols-2 grid-cols-1': isRegister }, 'w-full h-full')}>
            {isRegister ? (
              <div className="w-full h-full hidden t-lg:block">
                <RegisterVideos />
              </div>
            ) : null}

            <div className="h-full h-full flex flex-col overflow-auto">
              <div className="m-auto h-fit max-w-[80rem] w-full t-sm:w-[32rem]">{children}</div>
              {oferta}
            </div>
          </div>
        </>
      ) : null}

      {!!globalError ? (
        <Modal isOpen={!!globalError} onClose={closeModal}>
          <div className="whitespace-pre-wrap font-gilroy-500 text-xl mb-16 text-center">
            {globalError}
          </div>
          <Button variant="turquoise" onClick={closeModal} classNames="mx-auto">
            Закрыть
          </Button>
        </Modal>
      ) : null}

      {isSupportModalOpen ? (
        <Modal isOpen={isSupportModalOpen} onClose={handleCloseSupport}>
          <SupportForm onClose={handleCloseSupport} />
        </Modal>
      ) : null}

      <TariffEndModal />
    </>
  );
};
