import { FC } from 'react';
import { Button } from 'src/components/kit/Button';

import errorGif from 'src/assets/gifs/payment-error.gif';

export const Rejected: FC = () => {
  return (
    <div className="w-full h-full flex items-center justify-center px-5">
      <div className="max-w-[42rem] flex flex-col items-center">
        <div className="w-1/3">
          <img src={errorGif} alt="" className="w-full h-full" />
        </div>
        <div className="mb-5 font-gilroy-500 text-pink-100 text-xl t-lg:text-3xl text-center">
          Оплата не прошла
        </div>
        <div className="text-center text-grey-100 text-sm t-lg:text-xl mb-20 whitespace-pre-wrap">
          Оплата не прошла.{`\n`}Возможно произошел сбой сети.{`\n`}Попробуйте оплатить еще раз или
          обратитесь в поддержку
        </div>
        <div className="flex flex-col t-sm:flex-row gap-5">
          <Button
            href="/"
            variant="turquoise-outline"
            classNames="w-full t-sm:w-fit whitespace-nowrap"
          >
            Попробовать еще раз
          </Button>
          <Button href="/" variant="turquoise" classNames="w-full t-sm:w-fit whitespace-nowrap">
            Обратиться в поддержку
          </Button>
        </div>
      </div>
    </div>
  );
};
