import { FC, useState } from 'react';
import { Button } from 'src/components/kit/Button';
import { useForm } from 'react-hook-form';
import { Input } from 'src/components/kit/Input';
import { WS_SUPPORT_BASE_URL, WS_SUPPORT_EMAIL } from 'src/config';
import { useAppSelector } from 'src/hooks/useRedux';
import axios from 'axios';

interface SupportFormProps {
  onClose: () => void;
}

const API = axios.create({
  baseURL: WS_SUPPORT_BASE_URL,
});

API.interceptors.request.use((req: any) => {
  req.headers.EmailToken = 'jLI2teQV/EWQbIxCc3MWNA==';
  req.headers['Content-Type'] = 'application/json';
  req.headers['Access-Control-Allow-Origin'] = '*';
  return req;
});

export const SupportForm: FC<SupportFormProps> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ text: string }>({ mode: 'all' });
  const { user } = useAppSelector(state => state.user);

  const [loading, setLoading] = useState<boolean>(false);
  const [isSend, seIseSend] = useState<boolean>(false);

  const onSubmit = handleSubmit(async data => {
    setLoading(true);

    const body = [`Сообщение: ${data.text}`, `Email: ${user?.email}`, `Phone: ${user?.phone}`];

    const sendData = JSON.stringify({
      text: body.join('\n'),
      subject: `Запрос из админ панели`,
    });
    try {
      await API.post(`/Email/SendUniversal/?emailReciever=${WS_SUPPORT_EMAIL}`, sendData).finally(
        () => {
          setLoading(false);
          seIseSend(true);
        },
      );
    } catch (e) {
      setLoading(false);
      seIseSend(true);
    }
  });

  const handleClose = e => {
    e.preventDefault();
    onClose();
  };

  return (
    <form className="max-w-[40rem] mx-auto flex flex-col gap-8 mt-20 t-sm:mt-0" onSubmit={onSubmit}>
      <div className="whitespace-pre-wrap font-gilroy-500 text-xl text-center whitespace-pre-wrap">
        {isSend
          ? `Спасибо за обращение.\nВаш запрос обрабатывается.`
          : 'Возникла проблема? Напишите нам и наши специалисты помогут решить ее в кратчайшие сроки.'}
      </div>

      {!isSend ? (
        <>
          <Input
            type="textarea"
            register={register}
            data={{ placeholder: 'Введите текст сообщения', name: 'text', required: true }}
            errors={errors}
          />

          <div className="flex flex-col t-sm:flex-row justify-center w-full gap-2.5 t-sm:gap-5">
            <Button
              variant="turquoise-outline"
              onClick={handleClose}
              classNames="w-full t-sm:w-fit"
              disabled={loading}
            >
              Отмена
            </Button>
            <Button variant="turquoise" classNames="w-full t-sm:w-fit" disabled={loading}>
              Отправить
            </Button>
          </div>
        </>
      ) : null}

      {isSend ? (
        <div className="text-grey-100 font-gilroy-400 text-xs mx-auto w-fit">
          Наши специалисты свяжутся с Вами в ближайшее время
        </div>
      ) : null}
    </form>
  );
};
