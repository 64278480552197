import { FC, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

interface AppToolTip {
  id: string;
  text: string;
  classNames?: string;
}

export const AppToolTip: FC<AppToolTip> = ({ id, text, classNames }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (ref?.current) {
      setActive(ref?.current?.scrollWidth > ref?.current?.offsetWidth);
    }
  }, [text, ref]);

  return (
    <>
      <div className={classNames} data-tooltip-id={id} data-tooltip-content={text} ref={ref}>
        {text}
      </div>

      {active ? <Tooltip id={id} className="tooltip-white" /> : null}
    </>
  );
};
