import { FC, useMemo, useState } from 'react';
import { RateCard } from 'src/components/kit/Cards/RateCard';
import { Button } from 'src/components/kit/Button';
import { numberWithSpaces } from 'src/utils';
import { ControlledCheckBox } from 'src/components/ControlledCheckBox';
import { ChangeEmailModal } from 'src/components/Modals/ChangeEmailModal';
import { Modal } from 'src/components/kit/Modal';
import { setGlobalError, setIsSupportModalOpen } from 'src/store/common';
import { TariffDeclineCard } from 'src/components/Cards/TariffDeclineCard';
import { useTariff } from 'src/hooks/useTariff';
import { MonthType, PaymentInitializeType, TariffType } from 'src/config/types';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { months, monthsNames } from 'src/config/data';
import PaymentService from 'src/services/PaymentService';
import TariffService from 'src/services/TariffService';

import cn from 'classnames';

interface TariffChoiceModalProps {
  initialTariff: TariffType;
}

export const TariffChoiceModal: FC<TariffChoiceModalProps> = ({ initialTariff }) => {
  const dispatch = useAppDispatch();
  const {
    shops: { shops },
    user: { tariffs, user },
  } = useAppSelector(state => state);
  const [currentMonth, setCurrentMonth] = useState<MonthType>(months[0]);
  const [selectedTariff, setSelectedTariff] = useState<TariffType>(initialTariff);
  const [isEmailAddModalOpen, setIsEmailAddModalOpen] = useState<boolean>(false);

  const openAddEmailModal = () => setIsEmailAddModalOpen(true);
  const closeAddEmailModal = () => setIsEmailAddModalOpen(false);

  const curTariff = useTariff();

  const checkLowerTariff = () => {
    if (selectedTariff.id.toString() === '5') {
      return true;
    }

    if (selectedTariff.id.toString() !== '1') {
      return selectedTariff.id >= (curTariff?.id || '1');
    }

    let productsCount = 0;

    shops.forEach(shop => (productsCount += +shop.productsCount));

    return +selectedTariff.goodsCount >= productsCount;
  };

  const date = useMemo(() => {
    const curDate =
      selectedTariff?.id.toString() === user?.subscriptionId?.toString()
        ? new Date(user?.subscriptionEnd)
        : new Date();

    const newDate = new Date(curDate.setMonth(curDate.getMonth() + currentMonth.value));

    return `${newDate.getDate()} ${monthsNames[newDate.getMonth()]}  ${newDate.getFullYear()} года`;
  }, [currentMonth, selectedTariff]);

  const price = useMemo(() => {
    return currentMonth.value * selectedTariff.price;
  }, [currentMonth, selectedTariff]);

  const handleBuy = async curEmail => {
    if (!user) {
      return;
    }

    if (selectedTariff?.id.toString() === '1') {
      try {
        await TariffService.changeUsersTariffFront({
          tariffId: +selectedTariff?.id,
          month: currentMonth.value,
        }).then(() => {
          if (!user?.subscriptionId) {
            window.location.reload();
          } else {
            dispatch(setGlobalError('Ваш тариф успешно изменен'));
          }
        });
      } catch (e) {}
      return;
    }

    if (selectedTariff?.id.toString() === '5') {
      dispatch(setIsSupportModalOpen(true));
      return;
    }

    if (!user?.email || !curEmail) {
      openAddEmailModal();
      return;
    }

    try {
      const params: PaymentInitializeType = {
        email: user?.email || curEmail,
        quantity: currentMonth.value,
        price: selectedTariff.price,
        name: selectedTariff.name,
        tariffId: selectedTariff.id,
      };
      const res = await PaymentService.initialize(params);

      if (res?.data?.paymentURL) {
        window.location.replace(res.data.paymentURL);
      }
    } catch (e) {}
  };

  const percent = (
    <div
      className={
        'text-white px-2 py-0.5 bg-pink-100 font-gilroy-600 text-small ' +
        'm-md:text-xs rounded-full m-md:px-2.5'
      }
    >
      %
    </div>
  );

  return (
    <div className="flex flex-col-reverse gap-12 t-sm:justify-between t-sm:flex-row t-sm:p-10 t-sm:pb-0">
      {curTariff ? (
        <div>
          <div className="pl-8 whitespace-pre-wrap font-gilroy-500 mb-5">{`Текущий тариф`}</div>
          <RateCard fullInfo noButton data={curTariff} />
        </div>
      ) : null}
      <div className="flex flex-col t-lg:w-[28rem]">
        <div className="flex mb-1 t-sm:mb-5 gap-2.5">
          {months.map(month => (
            <div
              key={`month-list-${month.value}`}
              className={cn(
                {
                  'cursor-pointer text-grey-100 opacity-70 hover:bg-turquoise-100 hover:text-white':
                    currentMonth.value !== month.value,
                  'text-white bg-turquoise-100': currentMonth.value === month.value,
                },
                'font-gilroy-500 h-fit rounded-xl transition-full flex items-center gap-1 ' +
                  'whitespace-nowrap p-2 m-md:p-2.5 text-xs m-md:text-base',
              )}
              onClick={() => setCurrentMonth(month)}
            >
              {month.name}
              {month.value === 12 ? percent : null}
            </div>
          ))}
        </div>

        <div className="text-grey-100 text-small t-sm:text-xs font-gilroy-400 mb-6 t-sm:mb-10">{`Действителен до ${date}`}</div>

        <div className="mb-8 flex flex-col gap-5 w-full">
          {tariffs.map(tariff => (
            <div
              key={`tariffs-list-modal-${tariff.id}`}
              className={cn(
                { 'cursor-pointer': tariff.id !== selectedTariff.id },
                'flex items-center group gap-2.5 t-sm:gap-5',
              )}
              onClick={() => setSelectedTariff(tariff)}
            >
              <ControlledCheckBox active={tariff.id === selectedTariff.id} />
              <div className="t-sm:-ml-2.5 text-blue-200 font-gilroy-600 text-sm t-sm:text-base w-24 t-sm:w-32 truncate">
                {tariff.name}
              </div>
              <div
                className={cn(
                  {
                    'text-turquoise-100': tariff.id.toString() !== '1',
                    'text-grey-100': tariff.id.toString() === '1',
                  },
                  'whitespace-nowrap',
                )}
              >
                {tariff.id.toString() === '1' ? 'бесплатно' : null}
                {tariff.id.toString() === '5' ? 'индивидуально' : null}
                {tariff.id.toString() !== '1' && tariff.id.toString() !== '5'
                  ? `${numberWithSpaces(tariff.price / 100)} ₽/мес`
                  : null}
              </div>
              {tariff.id.toString() === '4' ? (
                <>
                  <div className="t-sm:hidden">{percent}</div>
                  <div
                    className={
                      't-sm:block hidden text-white text-xs px-2.5 rounded-full py-0.5 ' +
                      'bg-pink-100 font-gilroy-600 ml-auto'
                    }
                  >
                    Выгодно
                  </div>
                </>
              ) : null}
            </div>
          ))}
        </div>

        {selectedTariff?.id.toString() === '1' &&
        curTariff?.id.toString() === '1' &&
        !user?.subscriptionId ? (
          <div
            className={
              'w-full t-sm:w-fit t-sm:ml-auto t-sm:mt-auto flex flex-col items-center gap-2.5'
            }
          >
            <Button variant="turquoise-outline" classNames="w-full t-sm:w-fit" onClick={handleBuy}>
              Выбрать
            </Button>
          </div>
        ) : null}

        {checkLowerTariff() &&
        !(
          curTariff?.id.toString() === selectedTariff?.id.toString() &&
          curTariff?.id.toString() === '1'
        ) ? (
          <div
            className={
              'w-full t-sm:w-fit t-sm:ml-auto t-sm:mt-auto flex flex-col items-center gap-2.5'
            }
          >
            <Button variant="turquoise-outline" classNames="w-full t-sm:w-fit" onClick={handleBuy}>
              {selectedTariff.id.toString() === '1' && curTariff?.id.toString() !== '1'
                ? 'Перейти'
                : null}

              {selectedTariff?.id === user?.subscriptionId ? 'Продлить' : null}

              {selectedTariff?.id.toString() === '5' ? 'Написать нам' : null}

              {selectedTariff?.id !== user?.subscriptionId &&
              selectedTariff?.id.toString() !== '5' &&
              selectedTariff.id.toString() !== '1'
                ? 'Купить выбранный'
                : null}
            </Button>

            <div className="text-grey-100 font-gilroy-400 text-xs">
              {selectedTariff.id.toString() === '1' ? 'бесплатно' : null}
              {selectedTariff.id.toString() !== '1' && selectedTariff.id.toString() !== '5'
                ? `${numberWithSpaces(price / 100)} ₽`
                : null}
            </div>
          </div>
        ) : null}

        {!checkLowerTariff() ? <TariffDeclineCard /> : null}
      </div>

      {isEmailAddModalOpen ? (
        <Modal isOpen={isEmailAddModalOpen} onClose={closeAddEmailModal} mobileClose>
          <ChangeEmailModal handleSave={handleBuy} />
        </Modal>
      ) : null}
    </div>
  );
};
