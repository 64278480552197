import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductsState } from './types';
import { fetchCurrency, fetchProducts, fetchStoreFilteringProducts } from './actions';
import { CurrencyType, ProductType } from 'src/config/types';

const initialState: ProductsState = {
  products: {},
  isLoadingProducts: true,
  storeFilteringProducts: {},
  isLoadingStoreFilteringProducts: true,
  currency: [],
  isLoadingCurrency: true,
  error: '',
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProducts.pending.type]: state => {
      state.isLoadingProducts = true;
      state.products = {};
    },
    [fetchProducts.fulfilled.type]: (state, action: PayloadAction<ProductType[]>) => {
      state.isLoadingProducts = false;
      const products = action.payload;

      products.forEach(product => {
        if (state.products?.[product.subgroupId]) {
          state.products[product.subgroupId] = [...state.products[product.subgroupId], product];
        } else {
          state.products[product.subgroupId] = [product];
        }
      });

      state.error = '';
    },
    [fetchProducts.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingProducts = false;
      state.error = action.payload;
    },
    [fetchStoreFilteringProducts.pending.type]: state => {
      state.isLoadingStoreFilteringProducts = true;
    },
    [fetchStoreFilteringProducts.fulfilled.type]: (
      state,
      action: PayloadAction<{ shopId: string; products: ProductType[] }>,
    ) => {
      const { products, shopId } = action.payload;

      state.isLoadingStoreFilteringProducts = false;
      state.storeFilteringProducts[shopId] = products;
      state.error = '';
    },
    [fetchStoreFilteringProducts.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingStoreFilteringProducts = false;
      state.error = action.payload;
    },
    [fetchCurrency.pending.type]: state => {
      state.isLoadingCurrency = true;
    },
    [fetchCurrency.fulfilled.type]: (state, action: PayloadAction<CurrencyType[]>) => {
      state.isLoadingCurrency = false;
      state.currency = action.payload;
      state.error = '';
    },
    [fetchCurrency.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingCurrency = false;
      state.error = action.payload;
    },
  },
});

export const {} = productsSlice.actions;

const reducer = productsSlice.reducer;
export default reducer;
