export const navList = [
  {
    text: 'Как создать чат-бот?',
    href: '',
  },
  {
    text: 'Как создать группу?',
    href: '',
  },
  {
    text: 'Как пользоваться Бриз-ботом?',
    href: '',
  },
  {
    text: 'Что такое токен магазина?',
    href: '',
  },
  {
    text: 'Как скачать Telegram?',
    href: '',
  },
  {
    text: 'Как подключить доставку?',
    href: '',
  },
];

export const howCreateChatBot = {
  title: `Создать чат-бот в Telegram очень просто. Для этого воспользуйтесь нашим приложением <a href=''>Bot Father</a> следуя подробной инструкции.`,
  leftList: [
    {
      title: `1. Создайте чат-бот, зайдите в Телеграм <a href=''>ссылка</a>`,
      classNames: '',
    },
    {
      title: `2. Запустить BotFather. В самом низу есть кнопка <a href=''>START</a>, нажмите на нее.`,
      classNames: 'mb-20',
    },
    {
      title: `3. Создать нового бота. Пропишите команду <a href=''>/newbot</a> или выберите в меню (меню находится внизу слева)`,
      classNames: 'mb-40',
    },
    {
      title: `4. Задать имя боту. С помощью имени Ваши клиенты смогут найти <a href=''>Ваш магазин</a>. Введите имя магазина, как указано на примере.`,
      classNames: 'mb-44',
    },
    {
      title: `5. Задать имя пользователя бота. Вы можете к имени магазина дописать приставку в виде “<a href=''>bot</a>” или “<a href=''>_bot</a>”`,
      classNames: '',
    },
  ],
  rightList: [
    {
      text: `/newbot`,
      answer: `Alright, a new bot. How are we going to call it? Please choose a name for your bot.`,
    },
    {
      text: `WinSolutions`,
      answer: `Good. Now let's choose a username for your bot. It must end in \`bot\`. Like this, for example: TetrisBot or tetris_bot.`,
    },
    {
      text: `WinSolutions_bot`,
      answer: `Done! Congratulations on your new bot. You will find it at t.me/WinSolutionsBot. You can now add a description, about section and profile picture for your bot, see /help for a list of commands. By the way, when you've finished creating your cool bot, ping our Bot Support if you want a better username for it. Just make sure the bot is fully operational before you do this.\n\nUse this token to access the HTTP API:\n\t<a>6234882711:AAEnX1QadvcNq</a>\n\t<a>CN13ELojQP2vSwWsDERjoc</a>\nKeep your token secure and store it safely, it can be used by anyone to control your bot.\n\nFor a description of the Bot API, see this page: https://core.telegram.org/bots/api`,
    },
  ],
};
