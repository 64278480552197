import { SupportApi } from 'src/services/Api/SupportApi';
import { GetSupportMessagesType } from 'src/config/types';
import { AxiosRequestConfig } from 'axios';

const getSupport = (shopId: string) => {
  return SupportApi.get(`/Help?shopId=${shopId}`);
};

const getSupportMessages = (params: GetSupportMessagesType) => {
  const paramsInString = Object.keys(params)
    .map(param => `${param}=${params[param]}`)
    .join('&');

  return SupportApi.get(`/Help/${params.userId}/messages?${paramsInString}`);
};

const sendSupportMessage = (formData: FormData, config?: AxiosRequestConfig) => {
  return SupportApi.post(`/Help`, formData, config);
};

const supportMarkAsRead = (shopId: string, userId: string) => {
  return SupportApi.get(`/Help/markAsRead?shopId=${shopId}&userId=${userId}`);
};

const SupportService = { getSupport, getSupportMessages, sendSupportMessage, supportMarkAsRead };

export default SupportService;
