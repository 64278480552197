import { FC } from 'react';
import { Input } from 'src/components/kit/Input';
import { AuthFormContainer } from 'src/containers/AuthFormContainer';
import { useForm } from 'react-hook-form';
import { Checkbox } from 'src/components/kit/CheckBox';
import { AppLink } from 'src/components/kit/AppLink';
import { Button } from 'src/components/kit/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks/useRedux';
import { sendAnalytics } from 'src/utils/analytics';
import { fetchShops } from 'src/store/shops/actions';
import { fetchOnBoarding } from 'src/store/user/actions';
import AuthService from 'src/services/AuthService';
import {
  EMAIL_VALIDATION_REGEXP,
  PASSWORD_VALIDATION_REGEXP,
  PHONE_VALIDATION_REGEXP,
} from 'src/config/regExp';
import { RegisterType } from 'src/config/types';

import oferta from 'src/assets/documents/Оферта.docx';

export const Registration: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RegisterType>({ mode: 'all' });

  const onSubmit = handleSubmit(async data => {
    await AuthService.register(data, dispatch).then(async () => {
      sendAnalytics('Registration');
      navigate('/dashboard/shops');
      await dispatch(fetchShops());
      await dispatch(fetchOnBoarding());
    });
  });

  return (
    <AuthFormContainer onSubmit={onSubmit} title="Регистрация" classNames="justify-center">
      <div className="flex flex-col gap-5 w-full">
        <Input
          register={register}
          data={{ placeholder: 'Логин', name: 'login', required: true }}
          errors={errors}
        />

        <Input
          register={register}
          data={{ placeholder: 'Телефон', name: 'phone', required: false }}
          errors={errors}
          registerOptions={{ pattern: PHONE_VALIDATION_REGEXP }}
        />

        <Input
          register={register}
          data={{ placeholder: 'Email', name: 'email', required: true }}
          errors={errors}
          registerOptions={{
            validate: (val: string) => {
              if (!EMAIL_VALIDATION_REGEXP.test(val)) {
                return 'Некорректный email';
              }
            },
          }}
        />

        <Input
          type="password"
          register={register}
          data={{ placeholder: 'Пароль', name: 'password', required: true }}
          errors={errors}
          registerOptions={{
            validate: (val: string) => {
              if (!PASSWORD_VALIDATION_REGEXP.test(val)) {
                return 'Пароль должен содержать не менее 8 символов, минимум по одной заглавной и прописной букве, миниму одну цифру и не должен содержать спецсимволы';
              }
            },
          }}
        />

        <Input
          type="password"
          register={register}
          data={{ placeholder: 'Повторите пароль', name: 'confirm-password', required: true }}
          errors={errors}
          registerOptions={{
            validate: (val: string) => {
              if (watch('password') != val) {
                return 'Пароли не совпадают!';
              }
            },
          }}
        />
      </div>

      <div className="w-full t-sm:w-80 flex flex-col gap-5">
        <Button variant="turquoise">Зарегистрироваться</Button>

        <Checkbox
          data={{ name: 'data-access', required: true }}
          register={register}
          errors={errors}
        >
          <div className="font-gilroy-500 text-grey-200 text-xs">
            Нажимая на кнопку Зарегистрироваться Вы соглашаетесь с условиями{' '}
            <a className="underline inline text-turquoise-100" href={oferta} download="Оферта.docx">
              Публичного соглашения
            </a>{' '}
            и на обработку персональных данных
          </div>
        </Checkbox>
      </div>

      <div className="flex items-center gap-2">
        <div className="font-gilroy-500 text-black-100">Уже есть аккаунт?</div>
        <AppLink href={'/'}>Войти</AppLink>
      </div>
    </AuthFormContainer>
  );
};
