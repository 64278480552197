import { FC, ReactNode, useRef, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { CheckBoxType } from 'src/config/types';

import { ReactComponent as CheckIcon } from 'src/assets/icons/kit/check.svg';
import cn from 'classnames';

interface CheckboxProps {
  data: CheckBoxType;
  register: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
  classNames?: string;
  errors?: FieldErrors;
  children?: ReactNode;
}

export const Checkbox: FC<CheckboxProps> = ({ data, register, classNames, errors, children }) => {
  const ref = useRef<HTMLLabelElement | null>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const isError = !!errors?.[data.name];

  const handleClick = () => {
    if (!ref || !ref.current) {
      return;
    }

    const input = (ref.current as HTMLLabelElement).querySelector('input');

    if (input) {
      input.checked = !input.checked;
      setChecked(input.checked);
    }
  };

  return (
    <label className={cn(classNames, 'flex cursor-pointer')} onClick={handleClick} ref={ref}>
      <input
        className="hidden"
        type="checkbox"
        {...register(data.name, {
          validate: {
            positive: v => (data.required ? !!v : !v || !!v),
          },
        })}
      />
      <div
        className={cn(
          {
            'border-turquoise-400': !isError,
            'border-pink-100': isError,
            'bg-turquoise-400': checked,
          },

          'mr-2.5 t-lg:mr-4 w-6 h-6 min-w-[1.5rem] flex items-center justify-center',
          'rounded border-2 transition-full',
        )}
      >
        <CheckIcon
          className={cn(
            { 'opacity-1': checked, 'opacity-0': !checked },
            'w-3 h-2.5 stroke-path-white transition-full',
          )}
        />
      </div>
      {children}
    </label>
  );
};
