import { Dispatch, FC, SetStateAction } from 'react';
import { TariffType } from 'src/config/types';
import { GradientBorder } from 'src/components/kit/GradientBorder';
import { Button } from 'src/components/kit/Button';
import { getZero, numberWithSpaces } from 'src/utils';
import { ControlledCheckBox } from 'src/components/ControlledCheckBox';

import cn from 'classnames';
import { useAppSelector } from 'src/hooks/useRedux';

interface RateCardProps {
  data: TariffType;
  noButton?: boolean;
  fullInfo?: boolean;
  current?: boolean;
  setSelectedTariff?: Dispatch<SetStateAction<TariffType | null>>;
  classNames?: string;
}

export const RateCard: FC<RateCardProps> = ({
  fullInfo,
  data,
  setSelectedTariff,
  current,
  noButton,
  classNames,
}) => {
  const { user } = useAppSelector(state => state.user);

  const date = () => {
    if (!current || !user || !user?.subscriptionEnd) {
      return '';
    }
    const endDate = new Date(user.subscriptionEnd);
    const date = `${getZero(endDate.getDate())}${endDate.getDate()}`;
    const month = `${getZero(endDate.getMonth() + 1)}${endDate.getMonth() + 1}`;

    return `${date}.${month}.${endDate.getFullYear()}`;
  };

  const handleSelect = () => {
    setSelectedTariff && setSelectedTariff(data);
  };

  return (
    <GradientBorder
      borderWidth="2px"
      borderGradient={cn({
        'bg-gradient-turquoise-300 t-lg:bg-none t-lg:bg-grey-700':
          !Boolean(current) && !Boolean(fullInfo),
        'bg-gradient-turquoise-300': Boolean(current),
      })}
      classNames="rounded-4xl t-lg:w-60 min-w-[15rem] w-full h-96"
    >
      <div
        className={cn(
          {
            't-lg:hover:bg-white t-lg:bg-white-200': !Boolean(current),
          },
          'py-8 px-4 t-lg:p-8 hover:shadow-turquoise-100 rounded-4xl bg-white',
          'h-full transition-full cursor-default flex flex-col items-center group',
          classNames,
        )}
      >
        <div className="mb-2.5 flex items-center gap-2">
          {current ? <ControlledCheckBox active /> : null}
          <div className="font-gilroy-500 text-xl t-lg:text-2xl">{data.name}</div>
        </div>

        {current ? (
          <div className="text-turquoise-100 font-gilroy-500 mb-1">ваш текущий тариф</div>
        ) : null}

        <div className="font-gilroy-400 text-grey-100 text-center whitespace-pre-wrap">
          {fullInfo ? `Доступно товаров\nдля загрузки:` : data.description}
        </div>

        {fullInfo ? (
          <div className="text-pink-100 font-gilroy-500 text-4xl mt-5">{data.goodsCount}</div>
        ) : null}

        {current ? (
          <div className="mt-auto whitespace-nowrap font-gilroy-600 gap-2">
            {`активен до ${date()} г.`}
          </div>
        ) : null}

        {fullInfo ? (
          <div className="font-gilroy-400 text-grey-100 text-center mt-auto mb-3 text-sm">
            Оплата:
          </div>
        ) : null}

        <div
          className={cn(
            {
              'mt-auto mb-8 t-lg:mb-12': !Boolean(fullInfo),
              'text-turquoise-100': Boolean(fullInfo) || Boolean(current),
              't-lg:text-grey-100': !Boolean(fullInfo) && !Boolean(current),
              'mt-8': Boolean(current),
            },
            'font-gilroy-500 text-2xl text-turquoise-100 ' +
              'group-hover:text-turquoise-100 transition-full whitespace-nowrap',
          )}
        >
          {data.id.toString() === '5' ? 'индивидуально' : null}
          {data.id.toString() === '1' ? 'Бесплатно' : null}
          {data.price && data.id.toString() !== '1' && data.id.toString() !== '1'
            ? `${numberWithSpaces(data.price / 100)} руб./мес`
            : null}
        </div>

        {fullInfo ? (
          <div className="font-gilroy-400 text-grey-100 text-sm text-center mt-5">{`${numberWithSpaces(
            (data.price / 100) * 12,
          )} ₽/год`}</div>
        ) : null}

        {!fullInfo ? (
          <div className="h-[3.25rem] mx-auto">
            {!noButton ? (
              <Button variant={current ? 'turquoise' : 'turquoise-light'} onClick={handleSelect}>
                {current ? 'Активно' : 'Выбрать'}
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    </GradientBorder>
  );
};
