import { ShopApi } from 'src/services/Api/ShopApi';
import { UpdateSubGroupType } from 'src/config/types';

const createSubGroup = (name: string, shopId: string, groupId: string) => {
  return ShopApi.post(`/Catalog/CreateSubGroup?name=${name}&shopId=${shopId}&groupId=${groupId}`);
};

const getSubGroups = (shopId: string, groupId: string) => {
  return ShopApi.post(`/Catalog/GetSubGroupList?shopId=${shopId}&groupId=${groupId}`);
};

const updateSubGroup = (id: string, shopId: string, name: string, groupId: string) => {
  return ShopApi.post(
    `/Catalog/UpdateSubGroup?id=${id}&shopId=${shopId}&name=${name}&groupId=${groupId}`,
  );
};

const deleteSubGroup = (id: string, shopId: string) => {
  return ShopApi.post(`/Catalog/UpdateSubGroup?id=${id}&shopId=${shopId}`);
};

const universalSubGroup = (params: UpdateSubGroupType[]) => {
  const data = JSON.stringify(params);

  return ShopApi.post(`/Catalog/UniversalSubGroup`, data);
};

const GroupService = {
  createSubGroup,
  getSubGroups,
  updateSubGroup,
  deleteSubGroup,
  universalSubGroup,
};

export default GroupService;
