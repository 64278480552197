import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersState } from './types';
import { fetchOrders, fetchOrderStatuses } from 'src/store/orders/actions';
import { OrderStatusType, OrderType, SortType } from 'src/config/types';
import { orderStatusColors } from 'src/config/data';

const initialState: OrdersState = {
  ordersSort: 'asc',
  orders: [],
  newOrders: 0,
  isLoadingOrders: true,
  orderStatuses: null,
  isLoadingOrderStatuses: true,
  error: '',
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrdersSort: (state, action: PayloadAction<SortType>) => {
      state.ordersSort = action.payload;
    },
    setIsLoadingOrders: (state, action: PayloadAction<boolean>) => {
      state.isLoadingOrders = action.payload;
    },
  },
  extraReducers: {
    [fetchOrders.pending.type]: state => {
      state.isLoadingOrders = true;
      state.orders = [];
      state.newOrders = 0;
    },
    [fetchOrders.fulfilled.type]: (
      state,
      action: PayloadAction<{ newOrdersCount: number; orders: OrderType[] }>,
    ) => {
      state.orders = action.payload.orders;
      state.newOrders = action.payload.newOrdersCount;
      state.isLoadingOrders = false;
      state.error = '';
    },
    [fetchOrders.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingOrders = false;
      state.error = action.payload;
    },
    [fetchOrderStatuses.pending.type]: state => {
      state.isLoadingOrderStatuses = true;
      state.orderStatuses = {};
    },
    [fetchOrderStatuses.fulfilled.type]: (state, action: PayloadAction<OrderStatusType[]>) => {
      const statuses: { [key: string]: OrderStatusType } = {};

      action.payload.forEach(el => {
        statuses[el.id] = { ...el, styles: orderStatusColors[el.id] };
      });

      state.isLoadingOrderStatuses = false;
      state.orderStatuses = statuses;
      state.error = '';
    },
    [fetchOrderStatuses.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingOrderStatuses = false;
      state.error = action.payload;
    },
  },
});

export const { setIsLoadingOrders, setOrdersSort } = ordersSlice.actions;

const reducer = ordersSlice.reducer;
export default reducer;
