import { Api } from 'src/services/Api/Api';
import { ChangeUserType } from 'src/config/types';

const getCurrentUserFromApi = () => {
  return Api.get('/Auth/get-username');
};

const changeUser = (params: ChangeUserType) => {
  return Api.post('/Auth/ChangeUsersData', params);
};

const changeUsersPassword = (password: string, jwt: string) => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + jwt,
    },
  };
  return Api.post('/Auth/ChangeUsersPassword', { password }, config);
};

const resetPasswordLink = (email: string) => {
  return Api.post('/Auth/SendChangePasswordLink', { email });
};

const UserService = {
  getCurrentUserFromApi,
  changeUser,
  resetPasswordLink,
  changeUsersPassword,
};

export default UserService;
