import { FC } from 'react';

import cn from 'classnames';

import { ReactComponent as QuestionIcon } from 'src/assets/icons/kit/question.svg';

interface ToolTipProps {
  text: string;
  href?: string;
  classNames?: string;
}

export const ToolTip: FC<ToolTipProps> = ({ text, href, classNames }) => {
  return (
    <div className={cn('flex items-center flex gap-1 cursor-default', classNames)}>
      <div
        className={
          'w-5 h-5 rounded-full flex items-center justify-center bg-turquoise-600 relative'
        }
      >
        <QuestionIcon className="w-3 h-3 fill-pink-100" />
      </div>
      <div className="font-gilroy-400 text-sm text-grey-100">{text}</div>
      <a
        href={href}
        target="_blank"
        className="font-gilroy-400 text-sm text-turquoise-100 underline"
      >
        Подробнее тут
      </a>
    </div>
  );
};
