import { FC, ReactNode, useRef } from 'react';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';

import { ReactComponent as SmallArrowIcon } from 'src/assets/icons/kit/arrow-small.svg';

import cn from 'classnames';

interface CarouselProps {
  itemWidth: number;
  classNames?: string;
  children?: ReactNode;
}

export const Carousel: FC<CarouselProps> = ({ itemWidth, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();

  const handleArrowClick = (type: 'right' | 'left') => {
    if (!ref?.current) {
      return;
    }
    const itemsWidth = 10 * itemWidth + 9 * 12;
    const scrollWidth = itemsWidth - ref.current.offsetWidth;

    if (type === 'right') {
      if (ref.current.scrollLeft === scrollWidth) {
        ref.current.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        return;
      }
      ref.current.scroll({
        top: 0,
        left: ref.current.scrollLeft + itemWidth + 12,
        behavior: 'smooth',
      });
    }

    if (type === 'left') {
      if (ref.current.scrollLeft === 0) {
        ref.current.scroll({
          top: 0,
          left: scrollWidth,
          behavior: 'smooth',
        });
        return;
      }
      ref.current.scroll({
        top: 0,
        left: ref.current.scrollLeft - itemWidth - 12,
        behavior: 'smooth',
      });
    }
  };

  const styles =
    'w-8 h-8 min-w-[2rem] rounded-full cursor-pointer items-center justify-center ' +
    'bg-white-200 hidden t-sm:flex';

  return (
    <div
      className={cn('relative flex gap-3 items-center')}
      style={{ width: width >= 768 ? 'calc(100% + 88px)' : '100%' }}
    >
      <div className={styles} onClick={() => handleArrowClick('left')}>
        <SmallArrowIcon className="stroke-path-grey-100 w-2.5 h-2.5 rotate-90" />
      </div>

      <div className="scrollbar-hide overflow-auto gap-3 flex w-full" ref={ref}>
        {children}
      </div>

      <div className={styles} onClick={() => handleArrowClick('right')}>
        <SmallArrowIcon className="stroke-path-grey-100 w-2.5 h-2.5 -rotate-90" />
      </div>
    </div>
  );
};
