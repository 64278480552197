import { FC, useEffect, useState } from 'react';
import { Modal } from 'src/components/kit/Modal';
import { Button } from 'src/components/kit/Button';
import { TariffChoiceModal } from 'src/components/Modals/TariffChoiceModal';
import { useTariff } from 'src/hooks/useTariff';
import { declOfNum } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setIsOpenTariffEndModal } from 'src/store/common';

export const TariffEndModal: FC = () => {
  const dispatch = useAppDispatch();
  const {
    common: { isOpenTariffEndModal },
    user: { user, isLoadingTariffs, isLoadingUser },
    shops: { shops, isLoadingShops },
  } = useAppSelector(state => state);
  const curTariff = useTariff();

  const [isOpenTariff, setIsOpenTariff] = useState<boolean>(false);
  const [productsCount, setProductsCount] = useState<number>(0);
  const [text, setText] = useState<string>('');

  const handleCloseTariff = () => setIsOpenTariff(false);
  const handleOpenTariff = () => setIsOpenTariff(true);

  useEffect(() => {
    if (
      !user ||
      !curTariff ||
      isLoadingShops ||
      isLoadingTariffs ||
      isLoadingUser ||
      window.location.pathname.includes('payment') ||
      window.location.pathname.includes('/dashboard/store-filtering') ||
      !window.location.pathname.includes('/dashboard')
    ) {
      return;
    }

    if (!shops?.length && !user?.subscriptionId) {
      setText('Срок действия вашего тарифа закончился.');
      dispatch(setIsOpenTariffEndModal(true));
      return;
    }

    let allProdsCount = 0;
    shops.forEach(shop => (allProdsCount += shop.productsCount || 0));
    setProductsCount(allProdsCount);

    if (allProdsCount > curTariff.goodsCount) {
      setText('Лимит товаров по текущему тарифу превышен.');
      dispatch(setIsOpenTariffEndModal(true));
    }

    if (
      !user?.subscriptionDescr &&
      !user?.subscriptionEnd &&
      !user?.subscriptionId &&
      !user?.subscriptionName &&
      !user?.subscriptionStart
    ) {
      setText('Срок действия вашего тарифа закончился.');
      dispatch(setIsOpenTariffEndModal(true));
    }
  }, [user, curTariff, shops]);

  return isOpenTariffEndModal ? (
    <Modal isOpen={isOpenTariffEndModal}>
      <div className="max-w-[40rem] flex flex-col items-center mx-auto mt-20 t-sm:mt-0">
        <div className="mb-5 w-fit text-xl font-gilroy-500">{text}</div>
        <div className="mb-16 text-grey-100 whitespace-pre-wrap text-xl text-center">
          Товары в ваших магазинах временно недоступны.{`\n`}В рамках бесплатной версии вы можете
          загружать <div className="inline text-turquoise-100">5</div> товаров.{`\n`}Чтобы
          продолжить пользоваться приложением, продлите ваш текущий тариф
          {productsCount - (curTariff?.goodsCount || 5) > 0 ? (
            <>
              , либо удалите{' '}
              <div className="inline text-turquoise-100">
                {productsCount - (curTariff?.goodsCount || 5)}{' '}
                {declOfNum(productsCount - (curTariff?.goodsCount || 5), [
                  'товар',
                  'товара',
                  'товаров',
                ])}
                .
              </div>
            </>
          ) : null}
        </div>
        <div className="flex flex-col t-sm:flex-row w-full t-sm:w-fit mx-auto gap-3 t-sm:gap-5">
          {productsCount - (curTariff?.goodsCount || 5) > 0 ? (
            <Button
              variant="turquoise-outline"
              classNames="w-full t-sm:w-fit"
              href="/dashboard/store-filtering"
            >
              Удалить товары
            </Button>
          ) : null}
          <Button variant="turquoise" classNames="w-full t-sm:w-fit" onClick={handleOpenTariff}>
            К тарифам
          </Button>
        </div>
      </div>
      {isOpenTariff && curTariff ? (
        <Modal isOpen={isOpenTariff} onClose={handleCloseTariff} mobileClose>
          <TariffChoiceModal initialTariff={curTariff} />
        </Modal>
      ) : null}
    </Modal>
  ) : null;
};
