import { FC } from 'react';
import cn from 'classnames';
import { ReactComponent as CheckIcon } from 'src/assets/icons/kit/check.svg';
import { GradientBorder } from 'src/components/kit/GradientBorder';

interface ControlledCheckBox {
  active: boolean;
  classNames?: string;
}

export const ControlledCheckBox: FC<ControlledCheckBox> = ({ active, classNames }) => {
  return (
    <GradientBorder
      borderWidth="1px"
      borderGradient="bg-gradient-turquoise-300"
      classNames={cn('rounded-md w-6 h-6 min-w-[1.5rem]', classNames)}
    >
      <div
        className={cn(
          { 'bg-white': !active },
          'w-full h-full rounded-md flex items-center justify-center transition-full',
        )}
      >
        <CheckIcon
          className={cn(
            {
              'opacity-1': active,
              'opacity-0': !active,
            },
            'stroke-path-white w-3.5 h-3.5 transition-full',
          )}
        />
      </div>
    </GradientBorder>
  );
};
