import { FC, useState } from 'react';
import { Input } from 'src/components/kit/Input';
import { Button } from 'src/components/kit/Button';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { fetchUser } from 'src/store/user/actions';
import { EMAIL_VALIDATION_REGEXP } from 'src/config/regExp';
import { ChangeUserType } from 'src/config/types';
import UserService from 'src/services/UserService';

interface ChangeEmailModalProps {
  handleSave: (email: string) => void;
}

export const ChangeEmailModal: FC<ChangeEmailModalProps> = ({ handleSave }) => {
  const dispatch = useAppDispatch();
  const {
    user: { user },
  } = useAppSelector(state => state);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = handleSubmit(async data => {
    if (!user) {
      return;
    }
    setIsLoading(true);
    const params: ChangeUserType = {
      email: data.email,
      phone: user?.phone,
      login: user?.name,
    };
    try {
      handleSave(data.email);
      await UserService.changeUser(params);
      await dispatch(fetchUser());
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="whitespace-pre-wrap font-gilroy-500 text-xl mb-16 text-center">
        Пожалуйста, введите ваш email.
      </div>

      <Input
        register={register}
        data={{ placeholder: 'Email', name: 'email', required: true }}
        registerOptions={{
          validate: (val: string) => {
            if (!EMAIL_VALIDATION_REGEXP.test(val)) {
              return 'Некорректный email';
            }
          },
        }}
        classNames="mb-8 t-sm:max-w-[40rem] mx-auto"
        errors={errors}
      />

      <Button variant="turquoise" disabled={isLoading} classNames="mx-auto">
        Продолжить
      </Button>
    </form>
  );
};
