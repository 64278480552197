import { createAsyncThunk } from '@reduxjs/toolkit';
import OrderService from 'src/services/OrderService';
import { SortType } from 'src/config/types';

export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async (data: { shopId: string; direction: SortType }, thunkAPI) => {
    try {
      const response = await OrderService.getOrders(data.shopId, data.direction);

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить заказы');
    }
  },
);

export const fetchOrderStatuses = createAsyncThunk('orders/fetchStatuses', async (_, thunkAPI) => {
  try {
    const response = await OrderService.getOrderStatuses();

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить типы заказов');
  }
});
