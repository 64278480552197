export const addMask = () => {
  document.querySelectorAll('.phone-mask').forEach(element => {
    const input = element as HTMLInputElement;
    let keyCode: number;
    const mask = (event: any) => {
      event?.keyCode && (keyCode = event?.keyCode);
      const pos = input?.selectionStart || 0;
      if (pos < 3) event.preventDefault();
      let i = 0;
      const matrix = '+7 (___) ___-__-__';
      const def = matrix.replace(/\D/g, '');
      const val = input.value.replace(/\D/g, '');
      let new_value = matrix.replace(/[_\d]/g, function (a) {
        return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
      });
      i = new_value.indexOf('_');
      if (i != -1) {
        i < 5 && (i = 3);
        new_value = new_value.slice(0, i);
      }
      const reg = matrix
        .substr(0, input.value.length)
        .replace(/_+/g, function (a) {
          return '\\d{1,' + a.length + '}';
        })
        .replace(/[+()]/g, '\\$&');
      const newReg = new RegExp('^' + reg + '$');
      if (!newReg.test(input.value) || input.value.length < 5 || (keyCode > 47 && keyCode < 58))
        input.value = new_value;
      if (event.type == 'blur' && input.value.length < 5) input.value = '';
    };

    input.addEventListener('input', mask, false);
    input.addEventListener('focus', mask, false);
    input.addEventListener('blur', mask, false);
    input.addEventListener('keydown', mask, false);
  });
};
