import { ChangeEvent, FC } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/hooks/useRedux';
import { setGlobalError } from 'src/store/common';
import SupportService from 'src/services/SupportService';

import { ReactComponent as PaperClipIcon } from 'src/assets/icons/kit/paper-clip.svg';
import { ReactComponent as ArrowBigIcon } from 'src/assets/icons/kit/arrow-big.svg';

interface SupportSendFormProps {
  userId: string;
  shopId: string;
  onSend: () => void;
}

export const SupportSendForm: FC<SupportSendFormProps> = ({ userId, onSend, shopId }) => {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, reset } = useForm({ mode: 'all' });

  const onSubmit = handleSubmit(async data => {
    const { message } = data;

    if (!message) {
      return;
    }

    const formData = new FormData();
    formData.append('Files', '');
    formData.append('UserId', userId);
    formData.append('ShopId', shopId);
    formData.append('Message', message);

    const config = {
      headers: { 'Content-Type': `multipart/form-data;` },
    };

    await SupportService.sendSupportMessage(formData, config);

    onSend();
    reset();
  });

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) {
      return;
    }

    const file = e.target.files[0];

    const filesize = (file.size / 1024 / 1024).toFixed(4);

    if (+filesize > 20) {
      dispatch(setGlobalError('Файл не должне превышать 20Мб'));
      return;
    }

    const formData = new FormData();
    formData.append('Files', new Blob([file]), file.name);
    formData.append('UserId', userId);
    formData.append('ShopId', shopId);
    formData.append('Message', '');

    const config = {
      headers: { 'Content-Type': `multipart/form-data;` },
      // onUploadProgress: progressEvent => {
      // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      // },
    };

    await SupportService.sendSupportMessage(formData, config);

    onSend();
  };

  return (
    <form className="px-4 py-2.5" onSubmit={onSubmit}>
      <div className="w-full rounded-4xl flex overflow-hidden hover:shadow-turquoise-100">
        <div className="bg-turquoise-rgb-100 h-full w-full px-8 py-3 flex gap-3 items-center">
          <label className="cursor-pointer">
            <PaperClipIcon className="w-5 h-5 fill-grey-100" />
            <input
              className="hidden"
              onChange={handleFileChange}
              type="file"
              name="file"
              multiple={false}
            />
          </label>
          <input
            {...register('message')}
            placeholder="Введите текст сообщения"
            className="bg-transparent w-full h-auto resize-none font-gilroy-400 t-lg:text-lg text-sm"
          />
        </div>
        <button
          className={
            'ml-auto text-white h-full bg-turquoise-100 px-8 py-3 flex ' +
            'items-center font-gilroy-600 text-xl cursor-pointer'
          }
          type="submit"
        >
          <ArrowBigIcon className="fill-white w-5 h-5 t-lg:hidden" />
          <div className="hidden t-lg:block">Отправить</div>
        </button>
      </div>
    </form>
  );
};
