import { ShopApi } from 'src/services/Api/ShopApi';
import { LocalShopType } from 'src/config/types';
import { AxiosRequestConfig } from 'axios';

const getShops = () => {
  return ShopApi.get('/Shop/GetShops');
};

const createShop = (shop: LocalShopType) => {
  return ShopApi.post('/Shop/CreateShop', shop);
};

const updateShop = (shop: LocalShopType, shopId: string) => {
  return ShopApi.post(`/Shop/UpdateShop?shopId=${shopId}`, shop);
};

const deleteShop = (shopId: string) => {
  return ShopApi.post(`/Shop/RemoveShop?shopId=${shopId}`);
};

const importCatalog = (params: { shopId: string; url: string }) => {
  return ShopApi.post(`/Catalog/Import`, params);
};

const importCatalogFromExcel = (formData: FormData, config?: AxiosRequestConfig) => {
  return ShopApi.post(`/Catalog/ImportData`, formData, config);
};

const exportCatalog = (shopId: string) => {
  return ShopApi.get(`/Catalog/Export?shopId=${shopId}`);
};

const getPresets = () => {
  return ShopApi.get(`/Shop/GetPresets`);
};

const ShopService = {
  getShops,
  createShop,
  updateShop,
  deleteShop,
  importCatalog,
  importCatalogFromExcel,
  exportCatalog,
  getPresets,
};

export default ShopService;
