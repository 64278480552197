import { FC, useState } from 'react';
import { Input } from 'src/components/kit/Input';
import { AuthFormContainer } from 'src/containers/AuthFormContainer';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/kit/Button';
import { useAppDispatch } from 'src/hooks/useRedux';
import { setGlobalError } from 'src/store/common';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { PASSWORD_VALIDATION_REGEXP } from 'src/config/regExp';
import UserService from 'src/services/UserService';

export const ChangePassword: FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = handleSubmit(async data => {
    const { password } = data;
    const jwt = location.search.substring(1) || '';

    setLoading(true);
    try {
      UserService.changeUsersPassword(password, jwt)
        .then(() => {
          dispatch(setGlobalError('Ваш пароль успешно изменен.'));
          navigate('/');
        })

        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  });

  return (
    <AuthFormContainer onSubmit={onSubmit} title="Введите новый пароль" classNames="justify-center">
      <div className="flex flex-col gap-5 w-full">
        <Input
          type="password"
          register={register}
          data={{ placeholder: 'Пароль', name: 'password', required: true }}
          errors={errors}
          registerOptions={{
            validate: (val: string) => {
              if (!PASSWORD_VALIDATION_REGEXP.test(val)) {
                return 'Пароль должен содержать не менее 8 символов, минимум по одной заглавной и прописной букве, миниму одну цифру и не должен содержать спецсимволы';
              }
            },
          }}
        />

        <Input
          type="password"
          register={register}
          data={{ placeholder: 'Повторите пароль', name: 'confirm-password', required: true }}
          errors={errors}
          registerOptions={{
            validate: (val: string) => {
              if (watch('password') != val) {
                return 'Пароли не совпадают!';
              }
            },
          }}
        />
      </div>
      <Button disabled={loading} variant="turquoise" type="submit">
        Сменить пароль
      </Button>
    </AuthFormContainer>
  );
};
