import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import { Button } from 'src/components/kit/Button';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setGlobalError } from 'src/store/common';
import { Modal } from 'src/components/kit/Modal';
import { DeleteModal } from 'src/components/Modals/DeleteModal';
import { Loader } from 'src/components/Loader';
import ShopService from 'src/services/ShopService';

import pattern from 'src/assets/documents/шаблон.xlsx';

interface ImportCatalogFormProps {
  shopId: string;
  onSend: () => void;
}

export const ImportCatalogForm: FC<ImportCatalogFormProps> = ({ shopId, onSend }) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const { shops } = useAppSelector(state => state.shops);
  const shop = useMemo(() => shops.find(shop => shop.id.toString() === shopId), [shops, shopId]);

  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const closeDeleteModal = () => {
    setFile(null);
    setIsOpenDeleteModal(false);
    onSend();
  };

  const handleSelectFile = () => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) {
      return;
    }

    setFile(e.target.files[0]);
    setIsOpenDeleteModal(true);
  };

  const generateDeleteText = () => {
    const productsText = shop?.productsCount
      ? `Данный каталог сдержит ${shop?.productsCount} активных товаров.\n`
      : '';

    return `Внимание!\nПри загрузке товаров из файла, каталог будет перезаписан!\n${productsText}`;
  };

  const handleImport = async () => {
    if (!file) {
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('Files', new Blob([file]), file.name);
    formData.append('ShopId', shopId);

    const config = {
      headers: { 'Content-Type': `multipart/form-data;` },
    };
    await ShopService.importCatalogFromExcel(formData, config)
      .then(() => {
        dispatch(setGlobalError('Каталог успешно загружен!'));
      })
      .catch(({ response }) => {
        dispatch(setGlobalError(response?.data || 'Не удалось загрузить каталог!'));
      })
      .finally(() => {
        setLoading(false);
        setFile(null);
        onSend();
      });
  };

  return (
    <>
      {loading ? (
        <Loader classNames="w-1/3 h-1/3 mx-auto" />
      ) : (
        <div className="flex flex-col items-center">
          <div className="mx-auto max-w-[40rem] text-xl font-gilroy-500 mb-10 text-center">
            Для того чтобы воспользоваться функцией импорта из Excel выберите нужное действие
          </div>

          <div className="mx-auto gap-5 flex flex-col t-sm:flex-row w-full t-sm:w-fit">
            <Button
              disabled={loading}
              onClick={handleSelectFile}
              variant="turquoise-outline"
              classNames="w-full t-sm:w-72"
            >
              Загрузить шаблон
            </Button>

            <input
              ref={ref}
              type="file"
              name="file"
              accept=".xlsx,.xls"
              multiple={false}
              className="hidden"
              onChange={handleFileChange}
            />

            <a href={pattern} download="шаблон.xlsx" className="w-full t-sm:w-72">
              <Button variant="turquoise" classNames="w-full t-sm:w-72">
                Скачать пример
              </Button>
            </a>
          </div>
        </div>
      )}

      {isOpenDeleteModal ? (
        <Modal isOpen={isOpenDeleteModal} onClose={closeDeleteModal}>
          <DeleteModal
            removeText="Загрузить шаблон"
            text={generateDeleteText()}
            handleDelete={handleImport}
            handleClose={closeDeleteModal}
          />
        </Modal>
      ) : null}
    </>
  );
};
