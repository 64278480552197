import { SupportApi } from 'src/services/Api/SupportApi';
import { SortType, SupportProductType } from 'src/config/types';

const getOrders = (shopId: string, direction: SortType, offset = 0) => {
  return SupportApi.get(
    `/Catalog/GetOrders?shopId=${shopId}&direction=${direction}&offset=${offset}`,
  );
};

const getOrderStatuses = () => {
  return SupportApi.get(`/Catalog/GetOrderStatuces`);
};

const changeOrderStatus = (orderId: string, statusId: string) => {
  return SupportApi.post(`/Catalog/ChangeOrderStatus?orderId=${orderId}&statusId=${statusId}`);
};

const changeOrder = (params: { orderId: string; shopId: string; goods: SupportProductType[] }) => {
  return SupportApi.post(`/Catalog/ChangeOrder`, params);
};

const OrderService = { getOrders, getOrderStatuses, changeOrderStatus, changeOrder };

export default OrderService;
