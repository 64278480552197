import { FC } from 'react';

import cn from 'classnames';

interface UserLogoProps {
  image?: string;
  name?: string;
  classNames?: string;
}

export const UserLogo: FC<UserLogoProps> = ({ image, name, classNames }) => {
  return (
    <div
      className={cn('flex items-center justify-center rounded-full overflow-hidden', classNames)}
    >
      {image ? (
        <img src={image} alt="" className="w-full h-full object-contain" />
      ) : (
        <div className="text-2xl text-white font-gilroy-600">{name?.slice(0, 1).toUpperCase()}</div>
      )}
    </div>
  );
};
