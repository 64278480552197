import { FC } from 'react';

import loadGif from 'src/assets/gifs/loading.gif';

import cn from 'classnames';

interface LoaderProps {
  classNames?: string;
}

export const Loader: FC<LoaderProps> = ({ classNames }) => {
  return (
    <div className={cn('', classNames)}>
      <img src={loadGif} alt="" className="w-full h-full" />
    </div>
  );
};
