import { Step } from 'react-joyride';

export const commonOnBoardingState: Step[] = [
  {
    target: '#orders-button-onboarding',
    content: 'Список ваших заказов, новых, в работе и закрытых',
    disableBeacon: true,
  },
  {
    target: '#my-shop-button-onboarding',
    content:
      'Здесь вы можете создать магазин, увидеть уже созданные вами магазины, отредактировать их и перейти в каталог товаров',
    disableBeacon: true,
  },
  {
    target: '#profile-button-onboarding',
    content:
      'Здесь вы можете ознакомиться с данными своего профиля, с вашим текущим тарифом и отредактировать их',
    disableBeacon: true,
  },
  {
    target: '#tariff-button-onboarding',
    content: 'Тут всегда можно выбрать наиболее удобный тариф',
    disableBeacon: true,
  },
  {
    target: '#faq-button-onboarding',
    content: 'Здесь вы можете ознакомиться с часто задаваемыми вопросами',
    disableBeacon: true,
  },
  {
    target: '#chats-button-onboarding',
    content: 'Здесь вы можете общаться с вашими клиентами',
    disableBeacon: true,
  },
  {
    target: '#create-shop-button-onboarding',
    content: 'Добавьте магазин, заполните все поля данными и заполните магазин товарами',
    disableBeacon: true,
  },
  {
    target: '#support-button-onboarding',
    content: 'Остаются вопросы? Нужна помощь? Обратитесь в тех. поддержку',
    disableBeacon: true,
  },
];

export const createShopOnBoardingState: Step[] = [
  {
    target: '#shop-logo-onboarding',
    content: 'Загрузите логотип вашего магазина. Это важная часть стиля вашего магазина',
    disableBeacon: true,
  },
  {
    target: '#shop-name-onboarding',
    content: 'Название магазина. То, как оно будет выглядеть для ваших клиентов',
    disableBeacon: true,
  },
  {
    target: '#shop-description-onboarding',
    content: 'Описание магазина, это информация для вас и ваших менеджеров',
    disableBeacon: true,
  },
  {
    target: '#shop-colors-onboarding',
    content: 'Выбирайте лучшее цветовое решение для вашего магазина',
    disableBeacon: true,
  },
  {
    target: '#shop-bot-token-onboarding',
    content:
      'Скопируйте сюда токен от чат-бота Телеграм. Его можно получить, прочитав инструкцию по ссылке',
    disableBeacon: true,
  },
  {
    target: '#shop-chat-token-onboarding',
    content:
      'Скопируйте сюда ИД телеграм-чата, в который бот-помощник будет присылать заказы и сообщения ваших клиентов',
    disableBeacon: true,
  },
];

export const createCatalogOnBoardingState: Step[] = [
  {
    target: '#add-group-button-onboarding',
    content: 'Создание групп для удобной навигации по магазину',
    disableBeacon: true,
  },
  {
    target: '#import-catalog-button-onboarding',
    content:
      'Тут можно загрузить товары из excel файла. Формат файла должен быть точно таким, какой вы скачали в шаблоне',
    disableBeacon: true,
  },
  {
    target: '#export-catalog-button-onboarding',
    content:
      'Тут можно скачать файл с товарами в правильном формате и дополнить или заменить товары, а так же изменить их параметры',
    disableBeacon: true,
  },
];

export const createProductOnBoardingState: Step[] = [
  {
    target: '#product-image-onboarding',
    content: 'Загрузите фото товара',
    disableBeacon: true,
  },
  {
    target: '#product-name-onboarding',
    content: 'Укажите название товара',
    disableBeacon: true,
  },
  {
    target: '#product-description-onboarding',
    content: 'Опишите товар, укажите его основные свойства',
    disableBeacon: true,
  },
  {
    target: '#product-stats-onboarding',
    content:
      'Если товар имеет различия, не влияющие на цену, тут можно задать характеристики, такие как цвет, размер и т.д.',
    disableBeacon: true,
  },
  {
    target: '#product-old-price-onboarding',
    content: 'Установите базовую цену товара',
    disableBeacon: true,
  },
  {
    target: '#product-discount-onboarding',
    content: 'Укажите размер скидки',
    disableBeacon: true,
  },
  {
    target: '#product-currency-onboarding',
    content: 'Выберите валюту цены',
    disableBeacon: true,
  },
  {
    target: '#product-discount-type-onboarding',
    content: 'Здесь вы можете указать тип скидки - в процентах или в вашей валюте',
    disableBeacon: true,
  },
  {
    target: '#product-new-price-onboarding',
    content: 'Тут будет итоговая цена товара со скидкой',
    disableBeacon: true,
  },
  {
    target: '#product-subgroup-onboarding',
    content: 'Поместите товар в нужную подгруппу',
    disableBeacon: true,
  },
];
