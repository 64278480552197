import { FC, useState } from 'react';
import { ProductType } from 'src/config/types';
import { Button } from 'src/components/kit/Button';
import { GradientBorder } from 'src/components/kit/GradientBorder';
import { numberWithSpaces } from 'src/utils';
import { Modal } from 'src/components/kit/Modal';
import { ProductAddForm } from 'src/components/Forms/ProductAddForm';
import { DeleteModal } from 'src/components/Modals/DeleteModal';
import { useAppDispatch } from 'src/hooks/useRedux';
import { useParams } from 'react-router';
import { fetchProducts } from 'src/store/products/actions';
import { BasketIconButton } from 'src/components/IconButton/BasketIconButton';
import ProductService from 'src/services/ProductService';

import { ReactComponent as EditIcon } from 'src/assets/icons/kit/edit.svg';

import cn from 'classnames';
import { Tooltip } from 'react-tooltip';

interface ProductListCardProps {
  data: ProductType;
  onRemove?: (product: ProductType) => void;
  classNames?: string;
}

export const ProductListCard: FC<ProductListCardProps> = ({ data, onRemove, classNames }) => {
  const { shopId, groupId } = useParams();
  const dispatch = useAppDispatch();

  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const openAddModal = () => setIsOpenAddModal(true);
  const closeAddModal = () => setIsOpenAddModal(false);

  const openDeleteModal = () => setIsOpenDeleteModal(true);
  const closeDeleteModal = () => setIsOpenDeleteModal(false);

  const handleDeleteProduct = async () => {
    if (onRemove) {
      onRemove(data);
    }

    if (!shopId && !groupId) {
      return;
    }

    await ProductService.deleteProduct(data.id, shopId || '');
    await dispatch(fetchProducts({ shopId: shopId || '', groupIds: groupId || '' }));
  };

  return (
    <>
      <GradientBorder
        borderWidth="2px"
        borderGradient={
          data.isActive ? 't-sm:bg-none bg-gradient-turquoise-300' : 'bg-grey-300 hover:bg-white'
        }
        classNames="rounded-xl w-full"
      >
        <div
          className={cn(
            {
              'bg-white': data.isActive,
              'hover:bg-white hover:shadow-turquoise-100': !data.isActive,
            },
            'p-2.5 t-sm:px-5 t-sm:py-3.5 rounded-xl group transition-full flex gap-3',
            classNames,
          )}
        >
          <div
            className={cn(
              { 'opacity-30 group-hover:opacity-100': !data.isActive },
              'w-20 h-20 transition-full',
            )}
          >
            <img src={data.image} alt="" className="object-cover w-full h-full transition-full" />
          </div>
          <div className="flex flex-col t-sm:flex-row w-full t-sm:items-center t-sm:gap-8">
            <div className="w-32 m-sm:w-52 t-sm:w-48 t-lg:w-64 d-xs:w-96">
              <div
                className="text-small t-sm:text-sm font-gilroy-500 mb-0.5 truncate"
                data-tooltip-id={`product-name-${data.id}-tooltip`}
                data-tooltip-content={data.name}
              >
                {data.name}
              </div>

              <div
                className="text-extra-small t-sm:text-small text-grey-100 font-gilroy-500 truncate"
                data-tooltip-id={`product-description-${data.id}-tooltip`}
                data-tooltip-content={data.description}
              >
                {data.description}
              </div>
            </div>

            <div className="flex w-full t-sm:w-fit justify-between items-end gap-4 mt-auto t-sm:mt-0">
              <div className="flex gap-3 items-center">
                <div
                  className={
                    'font-gilroy-400 line-through text-grey-100 text-extra-small t-sm:text-xs'
                  }
                >
                  {data.oldPrice !== data.newPrice
                    ? `${numberWithSpaces(data.oldPrice)} ${data.currency || '₽'}`
                    : null}
                </div>
                <div
                  className={cn(
                    {
                      'text-turquoise-100': data.isActive,
                      'text-grey-100 group-hover:text-turquoise-100': !data.isActive,
                    },
                    'font-gilroy-700 text-sm t-sm:text-lg transition-full',
                  )}
                >
                  {`${numberWithSpaces(data.newPrice)} ${data.currency || '₽'}`}
                </div>
              </div>
              <div className="t-sm:hidden flex gap-2">
                <Button
                  variant="turquoise-icon"
                  classNames={cn({ 'bg-white-100': data.isActive, 'bg-white': !data.isActive })}
                  size="icon"
                >
                  <EditIcon className="w-3.5 h-3.5" />
                </Button>
                <BasketIconButton
                  image="basket"
                  onClick={!shopId && !groupId ? handleDeleteProduct : openDeleteModal}
                />
              </div>
            </div>

            <div className="t-sm:flex hidden gap-5 ml-auto">
              <Button variant="turquoise-outline" size="small" onClick={openAddModal}>
                Редактировать
              </Button>
              <BasketIconButton
                image="basket"
                onClick={!shopId && !groupId ? handleDeleteProduct : openDeleteModal}
              />
            </div>
          </div>
        </div>
      </GradientBorder>

      <Tooltip id={`product-name-${data.id}-tooltip`} className="tooltip-white" />
      <Tooltip id={`product-description-${data.id}-tooltip`} className="tooltip-white" />

      {isOpenDeleteModal ? (
        <Modal isOpen={isOpenDeleteModal} onClose={closeDeleteModal}>
          <DeleteModal
            text="Вы точно хотите удалить товар?"
            handleDelete={handleDeleteProduct}
            handleClose={closeDeleteModal}
          />
        </Modal>
      ) : null}

      {isOpenAddModal ? (
        <Modal isOpen={isOpenAddModal} onClose={closeAddModal}>
          <ProductAddForm
            defaultValues={data}
            onSave={closeAddModal}
            shopId={data.shopId}
            groupId={data.groupId}
          />
        </Modal>
      ) : null}
    </>
  );
};
