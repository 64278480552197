import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'src/components/kit/Button';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { calculateOrderInfo } from 'src/utils';
import { Select } from 'src/components/kit/Select';
import { fetchOrders } from 'src/store/orders/actions';
import { OrderType, SelectItemType } from 'src/config/types';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import OrderService from 'src/services/OrderService';

import cn from 'classnames';

interface OrderCardProps {
  loadMore: () => void;
  withObserver?: boolean;
  order: OrderType;
  openEdit: (order: OrderType) => void;
  classNames?: string;
}

export const OrderCard: FC<OrderCardProps> = ({
  loadMore,
  withObserver,
  order,
  openEdit,
  classNames,
}) => {
  const dispatch = useAppDispatch();
  const {
    shops: { selectedShop },
    orders: { orderStatuses, ordersSort },
  } = useAppSelector(state => state);
  const ref = useRef<HTMLDivElement>(null);

  const { width } = useWindowDimensions();

  const [isAll, setIsAll] = useState<boolean>(false);

  const orderParams = useMemo(() => calculateOrderInfo(order.orderGoods), [order]);

  const handleChangeStatus = async (status: SelectItemType) => {
    if (status.value === order.statusId.toString() || !selectedShop) {
      return;
    }

    await OrderService.changeOrderStatus(order.id, status.value);
    await dispatch(fetchOrders({ shopId: selectedShop.id, direction: ordersSort }));
  };

  const getOrderStatus = (id: string) =>
    orderStatuses
      ? {
          name: orderStatuses[id]?.name,
          value: orderStatuses[id]?.id,
          styles: orderStatuses[id]?.styles,
        }
      : ({} as SelectItemType);

  const orderInfo = [
    { name: 'Сумма к оплате:', value: `${orderParams.price} ₽` },
    { name: 'Всего товаров:', value: `${orderParams.count} шт.` },
    { name: 'Покупатель:', value: order.user.name, right: true },
    {
      name: 'Комментарий:',
      value: order?.commentary,
      right: true,
    },
  ];

  const orderStatusesList: SelectItemType[] = useMemo(
    () => (orderStatuses ? Object.keys(orderStatuses).map(status => getOrderStatus(status)) : []),
    [orderStatuses],
  );

  const showAll = () => setIsAll(true);

  const productsToShow = useMemo(() => {
    if (width < 360) {
      return 3;
    }
    if (width < 520) {
      return 4;
    }
    if (width < 768) {
      return 5;
    }
    if (width < 1024) {
      return 10;
    }

    return 9;
  }, [width]);

  const styles = 'w-14 h-14 min-w-[3.5rem] t-lg:w-16 t-lg:h-16 t-lg:min-w-[4rem]';

  const handleOpenEdit = () => openEdit(order);

  const observer = withObserver
    ? new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          handleLoadMore();
        }
      })
    : null;

  const handleLoadMore = () => {
    if (observer && withObserver && ref?.current) {
      observer.unobserve(ref.current);
      loadMore();
    }
  };

  useEffect(() => {
    if (observer && withObserver && ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (observer && withObserver && ref?.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      className={cn('bg-white rounded-3xl p-5 t-lg:p-8 gap-5 grid t-lg:grid-cols-5', classNames)}
      ref={ref}
    >
      <div>
        <div className="font-gilroy-600 text-xl">{order.id}</div>

        <div className="w-32 -ml-2 mb-1">
          <Select
            data={{
              placeholder: 'Выбрать магазин',
              name: 'name',
              list: orderStatusesList,
            }}
            setStateValue={handleChangeStatus}
            value={getOrderStatus(order.statusId.toString())}
            size="small"
            variant="white"
          />
        </div>
        {orderInfo.map(info =>
          info?.value ? (
            <div
              key={`info-line-${info.name}`}
              className={cn({ 't-lg:hidden': Boolean(info.right) }, 'flex text-sm gap-1')}
            >
              <p className="font-gilroy-400 inline">{`${info.name} `}</p>
              <p className="font-gilroy-600 inline">{info.value}</p>
            </div>
          ) : null,
        )}
      </div>
      <div className="hidden t-lg:flex flex-col">
        {orderInfo.map(info =>
          info?.value ? (
            <div
              key={`info-line-right-${info.name}`}
              className={cn({ 't-lg:hidden': !Boolean(info.right) }, 'text-sm gap-1')}
            >
              <p className="font-gilroy-400 inline">{`${info.name} `}</p>
              <p className="font-gilroy-600 inline">{info.value}</p>
            </div>
          ) : null,
        )}
      </div>

      <div className="flex w-full flex-wrap gap-1 t-lg:gap-2 h-fit col-span-2">
        {order.orderGoods.map((good, i) => {
          const key = `order-product-${order.id}-${i}`;

          if (i > productsToShow && !isAll) {
            return <Fragment key={key} />;
          }

          if (i === productsToShow && !isAll) {
            return (
              <div
                key={key}
                className={
                  'bg-grey-100 cursor-pointer font-gilroy-400 text-lg flex items-center ' +
                  'justify-center opacity-50 ' +
                  styles
                }
                onClick={showAll}
              >
                +{order.orderGoods.length - productsToShow}
              </div>
            );
          }

          return (
            <div key={key} className={styles}>
              <img className="w-full h-full object-contain" src={good.image} alt="" />
            </div>
          );
        })}
      </div>
      <Button
        variant="turquoise"
        classNames="w-full t-lg:w-52 t-lg:min-w-[13rem] whitespace-nowrap h-fit"
        onClick={handleOpenEdit}
        text="Детали заказа"
      />
    </div>
  );
};
