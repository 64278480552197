import { FC, useEffect, useState } from 'react';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { SupportUsersList } from 'src/components/SupportUsersList';
import { SupportMessagesList } from 'src/components/SupportChat';
import { setGlobalError } from 'src/store/common';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { GetSupportMessagesType, SupportIncidentType, SupportMessageType } from 'src/config/types';
import SupportService from 'src/services/SupportService';

import { ReactComponent as ArrowBigIcon } from 'src/assets/icons/kit/arrow-big.svg';

export const Support: FC = () => {
  const limit = 20;
  const intervalTime = 3000;

  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const {
    shops: { selectedShop },
  } = useAppSelector(state => state);

  const [messages, setMessages] = useState<SupportMessageType[]>([]);
  const [selectedIncident, setSelectedIncident] = useState<SupportIncidentType | null>(null);
  const [incidents, setIncidents] = useState<SupportIncidentType[]>([]);
  const [loadingIncidents, setLoadingIncidents] = useState(true);
  const [isMessagesLoading, setIsMessagesLoading] = useState<boolean>(true);

  useEffect(() => {
    const interval = setInterval(async () => {
      await getIncidents(true);
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [selectedShop, selectedIncident, messages]);

  useEffect(() => {
    const userId = searchParams.get('userId');
    if (!userId || !incidents.length) {
      return;
    }
    const incident = incidents.find(incident => incident.user.id.toString() === userId);
    if (incident) {
      handleSelectIncident(incident).then();
    }
  }, [incidents]);

  useEffect(() => {
    (async () => {
      await getIncidents();
    })();
  }, [selectedShop]);

  const markAsRead = (incidentId: string) => {
    setIncidents(prev => {
      const tempList = [...prev];
      const index = prev.findIndex(el => el.incidentId !== incidentId);

      if (index !== -1) {
        tempList[index] = { ...tempList[index], unreadMessagesCount: 0 };
      }

      return tempList;
    });
  };

  const updateMessages = async () => {
    if (!selectedIncident || !selectedShop) {
      return;
    }

    const params: GetSupportMessagesType = {
      userId: selectedIncident.user.id,
      shopId: selectedShop.id,
      LastMessageId: messages[0].id,
      direction: 'down',
      limit,
    };

    const res = await SupportService.getSupportMessages(params);

    if (res.data.length) {
      setMessages(prev => [...res.data.sort((a, b) => +b.id - +a.id), ...prev]);
    }
  };

  const getInitialMessages = async (incident?: SupportIncidentType) => {
    const curIncident = incident || selectedIncident;

    if (!curIncident || !selectedShop) {
      return;
    }

    setIsMessagesLoading(true);

    const params: GetSupportMessagesType = {
      userId: curIncident.user.id,
      shopId: selectedShop.id,
      LastMessageId: '0',
      direction: 'down',
      limit,
    };

    const res = await SupportService.getSupportMessages(params);

    setIsMessagesLoading(false);

    if (+curIncident.unreadMessagesCount > 0) {
      await SupportService.supportMarkAsRead(selectedShop?.id || '', curIncident.user.id).then(
        () => {
          markAsRead(curIncident.incidentId);
        },
      );
    }

    setMessages(res.data);
  };

  const getIncidents = async (update?: boolean) => {
    if (!selectedShop) {
      setLoadingIncidents(false);
      return;
    }

    try {
      await SupportService.getSupport(selectedShop.id)
        .then(({ data }) => {
          setIncidents(data);
          if (!selectedIncident) {
            return;
          }
          const temp: SupportIncidentType = data.find(
            incident => incident.incidentId === selectedIncident.incidentId,
          );
          if (!temp) {
            return;
          }
          if (temp.unreadMessagesCount > 0) {
            updateMessages();
          }
          setSelectedIncident(prev =>
            prev ? { ...prev, unreadMessagesCount: temp.unreadMessagesCount } : null,
          );
        })
        .finally(() => {
          !update && setLoadingIncidents(false);
        });
    } catch (e) {
      if (update) {
        return;
      }
      setLoadingIncidents(false);
      dispatch(setGlobalError('Ошибка при загрузке пользователей!'));
    }

    if (!update && selectedIncident) {
      handleSelectIncident(null).then();
    }
  };

  const handleResetUser = () => setSelectedIncident(null);

  const handleSelectIncident = async (incident: SupportIncidentType | null) => {
    if (!incident) {
      setSelectedIncident(null);
      return;
    }

    if (selectedIncident?.incidentId === incident.incidentId) {
      return;
    }

    const supportChat = document.querySelector('#support-chat');
    if (supportChat) {
      supportChat.scrollTop = 0;
    }

    setSelectedIncident(incident);
    await getInitialMessages(incident);
  };

  const handleSendMessage = async () => {
    await updateMessages();
  };

  return (
    <div className="flex h-full">
      <SupportUsersList
        incidents={incidents}
        loadingIncidents={loadingIncidents}
        selectedIncident={selectedIncident}
        handleSelectIncident={handleSelectIncident}
      />

      {width >= 1024 || selectedIncident ? (
        <div className="h-full w-full">
          {!selectedIncident && width >= 1024 ? (
            <div
              className={'w-full h-full flex items-center justify-center font-gilroy-500 text-2xl'}
            >
              Выберите пользователя
            </div>
          ) : null}

          {selectedIncident ? (
            <div className="h-full w-full flex flex-col justify-end">
              <div
                className={
                  'flex t-lg:hidden items-center mx-4 py-2.5 border-b-2 ' +
                  'border-turquoise-100 cursor-pointer mb-auto'
                }
                onClick={handleResetUser}
              >
                <ArrowBigIcon className="rotate-180 fill-grey-100 mr-5 w-4 h-4" />
                <div className="text-sm font-gilroy-400">{selectedIncident?.user?.name}</div>
              </div>

              <SupportMessagesList
                messages={messages}
                setMessages={setMessages}
                markAsRead={markAsRead}
                selectedIncident={selectedIncident}
                isMessagesLoading={isMessagesLoading}
                handleSendMessage={handleSendMessage}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {}
    </div>
  );
};
