import { FC, ReactNode, useRef, useState } from 'react';
import { FieldErrors } from 'react-hook-form';

import { ReactComponent as CheckIcon } from 'src/assets/icons/kit/check.svg';
import { ReactComponent as EyeIcon } from 'src/assets/icons/kit/eye.svg';
import { ReactComponent as PercentIcon } from 'src/assets/icons/kit/percent.svg';
import { ReactComponent as MoneyIcon } from 'src/assets/icons/kit/money.svg';

import cn from 'classnames';

interface SwitchProps {
  name: string;
  required?: boolean;
  register?: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
  classNames?: string;
  errors?: FieldErrors;
  isChecked?: boolean;
  setValue?: (arg: boolean) => void;
  children?: ReactNode;
  variant?: 'visible' | 'money';
}

export const Switch: FC<SwitchProps> = ({
  name,
  register,
  required,
  classNames,
  setValue,
  isChecked = false,
  children,
  variant = 'visible',
}) => {
  const ref = useRef<HTMLLabelElement | null>(null);
  const [checked, setChecked] = useState<boolean>(isChecked);

  const handleClick = () => {
    if (!ref || !ref.current) {
      return;
    }

    const input = (ref.current as HTMLLabelElement).querySelector('input');

    if (input) {
      input.checked = !input.checked;
      setValue && setValue(input.checked);
      setChecked(input.checked);
    }
  };

  const circleStyles = 'w-8 h-8 rounded-full flex items-center justify-center transition-full';
  const iconStyles = 'w-4 h-4 transition-full';

  const iconsVariant = {
    visible: {
      left: (
        <CheckIcon
          className={cn({ 'stroke-path-white': checked, 'stroke-path-grey': !checked }, iconStyles)}
        />
      ),
      right: (
        <EyeIcon
          className={cn({ 'fill-white': !checked, 'fill-white-100': checked }, iconStyles)}
        />
      ),
    },
    money: {
      left: (
        <PercentIcon
          className={cn({ 'fill-white': !checked, 'fill-white-100': checked }, iconStyles)}
        />
      ),
      right: (
        <MoneyIcon
          className={cn({ 'fill-white': !checked, 'fill-white-100': checked }, iconStyles)}
        />
      ),
    },
  };

  return (
    <label
      className={cn(classNames, 'flex cursor-pointer flex items-center gap-5')}
      onClick={handleClick}
      ref={ref}
    >
      {register ? (
        <input
          className="hidden"
          type="checkbox"
          {...register(name, {
            validate: {
              positive: v => (required ? !!v : !v || !!v),
            },
          })}
        />
      ) : (
        <input className="hidden" type="checkbox" />
      )}

      {children}

      <div className={cn('flex items-center rounded-full  bg-grey-rgb-300 transition-full')}>
        <div
          className={cn({ 'bg-turquoise-100': checked, 'bg-transparent': !checked }, circleStyles)}
        >
          {iconsVariant[variant].left}
        </div>
        <div
          className={cn({ 'bg-turquoise-100': !checked, 'bg-transparent': checked }, circleStyles)}
        >
          {iconsVariant[variant].right}
        </div>
      </div>
    </label>
  );
};
