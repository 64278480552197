import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from 'src/components/kit/Input';
import { Button } from 'src/components/kit/Button';
import { fetchUser } from 'src/store/user/actions';
import { setGlobalError } from 'src/store/common';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { ChangeUserType } from 'src/config/types';
import { EMAIL_VALIDATION_REGEXP, PHONE_VALIDATION_REGEXP } from 'src/config/regExp';
import UserService from 'src/services/UserService';

import cn from 'classnames';

interface ProfileFormProps {
  onClose?: () => void;
  classNames?: string;
}

export const ProfileForm: FC<ProfileFormProps> = ({ onClose, classNames }) => {
  const { user } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ChangeUserType>({ mode: 'all' });

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setValue('login', user.name);
      setValue('email', user.email);
      setValue('phone', user.phone);
    }
  }, [user]);

  const onSubmit = handleSubmit(async data => {
    if (user?.name === data.login && user?.phone === data.phone && user?.email === data.email) {
      return;
    }

    setLoading(true);

    try {
      await UserService.changeUser(data).finally(() => {
        setLoading(false);
        onClose && onClose();
        dispatch(setGlobalError('Ваши данные изменены!'));
      });
      await dispatch(fetchUser());
    } catch (e) {
      setLoading(false);
    }
  });

  return (
    <form
      onSubmit={onSubmit}
      className={cn('w-full t-sm:w-[32.5rem] mx-auto flex flex-col gap-5 items-center', classNames)}
    >
      <Input
        register={register}
        data={{ placeholder: 'Логин', name: 'login', required: true }}
        errors={errors}
      />
      <Input
        register={register}
        data={{ placeholder: 'Телефон', name: 'phone', required: false }}
        registerOptions={{ pattern: PHONE_VALIDATION_REGEXP }}
        errors={errors}
      />
      <Input
        register={register}
        data={{ placeholder: 'Email', name: 'email', required: true }}
        registerOptions={{
          validate: (val: string) => {
            if (!EMAIL_VALIDATION_REGEXP.test(val)) {
              return 'Некорректный email';
            }
          },
        }}
        errors={errors}
      />

      <div className="flex t-sm:w-fit t-sm:flex-row flex-col-reverse w-full gap-5 mt-10">
        <Button
          variant="turquoise-outline"
          disabled={loading}
          onClick={e => {
            e?.stopPropagation();
            onClose && onClose();
          }}
        >
          Отмена
        </Button>
        <Button variant="turquoise" type="submit" disabled={loading} classNames="ml-auto">
          Сохранить
        </Button>
      </div>
    </form>
  );
};
