import { FC } from 'react';
import { numberWithSpaces } from 'src/utils';
import { BasketIconButton } from 'src/components/IconButton/BasketIconButton';
import { AppToolTip } from 'src/components/AppToolTip';
import { SupportProductType } from 'src/config/types';

import { ReactComponent as MinusIcon } from 'src/assets/icons/kit/minus.svg';
import { ReactComponent as PLusIcon } from 'src/assets/icons/kit/plus.svg';

interface OrderProductCardProps {
  index: number;
  changeAmount: (index: number, amount: number) => void;
  product: SupportProductType;
}

export const OrderProductCard: FC<OrderProductCardProps> = ({ index, changeAmount, product }) => {
  const handleDelete = () => changeAmount(index, 0);
  const addAmount = () => changeAmount(index, product.amount + 1);
  const removeAmount = () => changeAmount(index, product.amount - 1);
  const handleChange = e => changeAmount(index, +e.target.value);

  const styles = 'w-3 h-3 group-hover:fill-turquoise-100 transition-full';
  const stylesWrap = 'group h-full flex items-center px-2 cursor-pointer';

  return (
    <div className="w-full rounded-xl flex gap-3 border border-grey-100 p-3">
      <div className="w-28 h-28 min-w-[7rem] t-sm:w-14 t-sm:h-14 t-sm:min-w-[3.5rem]">
        <img className="w-full h-full object-contain" src={product.image} alt="" />
      </div>

      <div className="w-full flex flex-col gap-0.5 t-sm:flex-row t-sm:gap-3 t-sm:justify-between">
        <div className="flex flex-col gap-0.5">
          <AppToolTip
            classNames="truncate w-24 m-sm:w-32 m-md:w-72 t-sm:w-64 font-gilroy-600 text-sm"
            id={`order-product-${product.id}-tooltip`}
            text={product.goodName}
          />

          <div className="flex text-sm gap-1">
            <div className="font-gilroy-400">Цена:</div>
            <div className="font-gilroy-600">{`${numberWithSpaces(product.newPrice)} ₽`}</div>
          </div>
        </div>
        <div className="flex flex-col gap-0.5 mt-auto t-sm:mt-0">
          <div className="text-grey-100 text-xs font-gilroy-400">Количество:</div>

          <div
            className={
              'flex items-center h-8 rounded-md border border-grey-700 overflow-hidden w-fit'
            }
          >
            <div className={stylesWrap} onClick={removeAmount}>
              <MinusIcon className={styles} />
            </div>
            <div className="border-r border-l border-grey-700 h-full px-1 w-11">
              <input
                value={product.amount}
                onChange={handleChange}
                type="number"
                className="input-number w-full text-center h-full font-gilroy-400 text-sm"
              />
            </div>
            <div className={stylesWrap} onClick={addAmount}>
              <PLusIcon className={styles} />
            </div>
          </div>
        </div>
      </div>

      <BasketIconButton onClick={handleDelete} image="basket" classNames="mt-auto" />
    </div>
  );
};
