import { FC } from 'react';
import { useAppDispatch } from 'src/hooks/useRedux';
import { setIsSupportModalOpen } from 'src/store/common';
import { useTariff } from 'src/hooks/useTariff';
import { Link } from 'react-router-dom';

import errorGif from 'src/assets/gifs/payment-error.gif';

export const TariffDeclineCard: FC = () => {
  const dispatch = useAppDispatch();
  const curTariff = useTariff();

  const handleOpenSupport = () => dispatch(setIsSupportModalOpen(true));

  const styles = 'cursor-pointer hover:underline text-blue-100 inline';

  return (
    <div
      className={
        'w-full border border-pink-100 rounded-3xl mt-auto p-5 flex flex-col m-md:flex-row ' +
        'gap-2.5 items-center bg-white-200'
      }
    >
      <div className="w-20 h-20 min-w-[5rem]">
        <img src={errorGif} alt="" className="w-full h-full" />
      </div>
      <div>
        <div className="mb-3 font-gilroy-600 text-pink-100">Вы не можете перейти на этот тариф</div>
        <div className="font-gilroy-500 text-grey-100 text-xs">
          {curTariff?.id === '1' ? (
            <>
              {' '}
              Ваш текущий тариф содержит большее количество товаров. Чтобы перейти на тариф
              <Link className={styles} to="/">
                {' '}
                удалите товары{' '}
              </Link>
              либо
              <div className={styles} onClick={handleOpenSupport}>
                {' '}
                обратитесь в поддержку{' '}
              </div>
            </>
          ) : (
            'Переход на более дешевый тариф недоступен'
          )}
        </div>
      </div>
    </div>
  );
};
