import { FC, useState } from 'react';
import { DashboardContainer } from 'src/containers/DashboardContainer';
import { RateCard } from 'src/components/kit/Cards/RateCard';
import { Loader } from 'src/components/Loader';
import { Modal } from 'src/components/kit/Modal';
import { TariffChoiceModal } from 'src/components/Modals/TariffChoiceModal';
import { useAppSelector } from 'src/hooks/useRedux';
import { TariffType } from 'src/config/types';

export const Rates: FC = () => {
  const { tariffs, isLoadingTariffs, user } = useAppSelector(state => state.user);

  const [selectedTariff, setSelectedTariff] = useState<TariffType | null>(null);

  const handleCloseModal = () => setSelectedTariff(null);

  return (
    <DashboardContainer
      title="Тарифы"
      description="Выберите подходящий тарифный план и оплатите онлайн"
    >
      {isLoadingTariffs ? <Loader classNames="mx-auto my-20 w-1/2 t-lg:w-1/3" /> : null}

      {!isLoadingTariffs ? (
        <div
          className={
            'flex flex-col gap-5 t-lg:flex-row d-lg:flex-nowrap items-center ' +
            'justify-center flex-wrap'
          }
        >
          {tariffs.map((tariff, i) => (
            <RateCard
              key={`rate-${i}`}
              data={tariff}
              current={user?.subscriptionId === tariff.id}
              setSelectedTariff={setSelectedTariff}
            />
          ))}
        </div>
      ) : null}

      {selectedTariff ? (
        <Modal isOpen={!!selectedTariff} mobileClose onClose={handleCloseModal}>
          <TariffChoiceModal initialTariff={selectedTariff} />
        </Modal>
      ) : null}
    </DashboardContainer>
  );
};
