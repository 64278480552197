import { FC, MouseEvent } from 'react';

import { ReactComponent as BasketIcon } from 'src/assets/icons/kit/basket.svg';
import { ReactComponent as LoupeIcon } from 'src/assets/icons/kit/loupe.svg';

import cn from 'classnames';

interface BasketIconButtonProps {
  onClick: (e?: MouseEvent) => void;
  classNames?: string;
  variant?: 'base' | 'sm';
  image: 'basket' | 'loupe';
}

export const BasketIconButton: FC<BasketIconButtonProps> = ({
  onClick,
  classNames,
  variant = 'base',
  image,
}) => {
  const style = cn(
    {
      'w-4 h-4': variant === 'base',
      'w-2.5 h-2.5': variant === 'sm',
    },
    'fill-grey-100 group-hover/button:fill-white',
  );

  const images = {
    basket: <BasketIcon className={style} />,
    loupe: <LoupeIcon className={style} />,
  };

  const handleClick = (e: MouseEvent) => {
    onClick(e);
  };

  return (
    <div
      className={cn(
        {
          'w-8 h-8 min-w-[2rem]': variant === 'base',
          'w-5 h-5 min-w-[1.25rem]': variant === 'sm',
        },
        'group/button rounded-full flex items-center justify-center',
        'cursor-pointer bg-white hover:bg-turquoise-100',
        classNames,
      )}
      onClick={handleClick}
    >
      {images[image]}
    </div>
  );
};
